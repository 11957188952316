import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { UserProvider } from './components/contexts/UserContext';
import { CartProvider } from './components/contexts/CartContext';
import { Provider } from 'react-redux';
import { store } from './store';
import { Web3Provider } from './components/contexts/Web3Context'; // Import Web3Provider
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './adjustForDevice';

const stripePromise = loadStripe('your-publishable-key-here');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider>
        <CartProvider>
          <Web3Provider> {/* Wrap with Web3Provider */}
            <Elements stripe={stripePromise}>
              <Router>
                <App />
              </Router>
            </Elements>
          </Web3Provider>
        </CartProvider>
      </UserProvider>
    </Provider>
  </React.StrictMode>
);
