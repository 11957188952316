// src/components/SplashPage.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const SplashPage = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Video play failed", error);
      });
    }
  }, []);

  const handleVideoEnd = () => {
    navigate('/store');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-black">
      <video
        ref={videoRef}
        onEnded={handleVideoEnd}
        className="w-full h-full object-cover"
        autoPlay
        muted
        playsInline
      >
        <source src="/Media/Videos/splash.MP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default SplashPage;
