import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaSave, FaClock, FaCheck, FaTruck, FaTimes, FaBell, FaEnvelope, FaFileDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

const OrderDetails = ({ orderId, onClose }) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingTrackingNumber, setEditingTrackingNumber] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setOrderDetails(response.data);
        setTrackingNumber(response.data.trackingNumber || '');
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch order details:', error);
        setError('Failed to fetch order details');
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  const handleTrackingNumberSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, {
        trackingNumber,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEditingTrackingNumber(false);
      toast.success('Tracking number updated successfully');
    } catch (error) {
      console.error('Failed to update tracking number:', error);
      toast.error('Failed to update tracking number');
    }
  };

  const sendNotification = async (type) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}/notify`, { type }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      toast.success(`Notification sent successfully via ${type}`);
    } catch (error) {
      console.error(`Failed to send ${type} notification:`, error);
      toast.error(`Failed to send ${type} notification`);
    }
  };

  const downloadInvoice = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}/invoice`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download invoice:', error);
      toast.error('Failed to download invoice');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!orderDetails) return <div>No order details found.</div>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Order Details</h2>
        <button onClick={onClose} className="text-red-500">
          <FaTimes size={24} />
        </button>
      </div>
      
      <p><strong>Order ID:</strong> {orderDetails.id}</p>
      <p><strong>Status:</strong> {orderDetails.status}</p>
      <p><strong>Total:</strong> £{orderDetails.total}</p>
      <p><strong>Order Date:</strong> {new Date(orderDetails.createdAt).toLocaleString()}</p>

      <h3 className="text-xl font-bold mt-4">Customer Information</h3>
      <p><strong>Name:</strong> {orderDetails.user?.username}</p>
      <p><strong>Email:</strong> {orderDetails.user?.email}</p>

      <h3 className="text-xl font-bold mt-4">Shipping Information</h3>
      <p><strong>Address:</strong> {orderDetails.shippingAddress || 'N/A'}</p>
      <p><strong>City:</strong> {orderDetails.shippingCity || 'N/A'}</p>
      <p><strong>Postal Code:</strong> {orderDetails.shippingPostalCode || 'N/A'}</p>

      <h3 className="text-xl font-bold mt-4">Tracking Number</h3>
      {editingTrackingNumber ? (
        <div className="flex items-center">
          <input
            type="text"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            className="p-2 border border-gray-400 rounded w-full mr-2"
          />
          <button
            onClick={handleTrackingNumberSave}
            className="bg-green-500 text-white p-2 rounded"
          >
            <FaSave /> Save
          </button>
        </div>
      ) : (
        <div className="flex items-center">
          <p>{trackingNumber || 'N/A'}</p>
          <button
            onClick={() => setEditingTrackingNumber(true)}
            className="ml-2 bg-blue-500 text-white p-2 rounded"
          >
            <FaEdit /> Edit
          </button>
        </div>
      )}

      <h3 className="text-xl font-bold mt-4">Products</h3>
      {orderDetails.products?.length > 0 ? (
        <ul>
          {orderDetails.products.map((product) => (
            <li key={product.id} className="border-b border-gray-300 py-2">
              <p><strong>Name:</strong> {product.name}</p>
              <p><strong>Quantity:</strong> {product.OrderProduct?.quantity}</p>
              <p><strong>Price:</strong> £{product.price}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No products found in this order.</p>
      )}

      <h3 className="text-xl font-bold mt-4">Actions</h3>
      <div className="flex space-x-4">
        <button
          onClick={downloadInvoice}
          className="bg-green-500 text-white p-2 rounded flex items-center"
        >
          <FaFileDownload className="mr-2" /> Download Invoice
        </button>
        <button
          onClick={() => sendNotification('email')}
          className="bg-yellow-500 text-white p-2 rounded flex items-center"
        >
          <FaEnvelope className="mr-2" /> Send Email
        </button>
        {/* Remove SMS button if you no longer need SMS notifications */}
      </div>
    </div>
  );
};

export default OrderDetails;
