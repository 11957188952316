import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FaChevronDown,
  FaChevronUp,
  FaSearch,
  FaSpinner,
  FaFileInvoice,
  FaBan,
  FaTruck,
  FaBox,
  FaCheckCircle,
  FaTimesCircle,
  FaEllipsisV,
  FaPrint,
  FaRedo,
  FaRegEye,
} from 'react-icons/fa';
import { useUser } from '../components/contexts/UserContext';
import Modal from 'react-modal';
import Pagination from '../components/Pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Modal.setAppElement('#root');

const Orders = () => {
  const { user } = useUser();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('date');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersOpen, setOrdersOpen] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orderNotes, setOrderNotes] = useState('');
  const [showDropdown, setShowDropdown] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(Array.isArray(response.data) ? response.data : []);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        setError('Failed to fetch orders');
        setLoading(false);
      }
    };

    fetchOrders();
  }, [token]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSort = (field) => {
    setSort(field);
  };

  const handleCancelOrder = async (orderId) => {
    if (!window.confirm('Are you sure you want to cancel/return this order?')) return;

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/orders/${orderId}`,
        { status: 'canceled' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      toast.success('Order canceled successfully!');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Failed to cancel order:', error);
      toast.error('Failed to cancel order');
    }
  };

  const handleDownloadInvoice = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}/invoice`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success('Invoice downloaded successfully!');
    } catch (error) {
      console.error('Failed to download invoice:', error);
      toast.error('Failed to download invoice');
    }
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (sort === 'date') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (sort === 'status') {
      return a.status.localeCompare(b.status);
    }
    if (sort === 'price') {
      return (
        b.Products.reduce(
          (acc, product) =>
            acc +
            product.OrderProduct.price * product.OrderProduct.quantity,
          0
        ) -
        a.Products.reduce(
          (acc, product) =>
            acc +
            product.OrderProduct.price * product.OrderProduct.quantity,
          0
        )
      );
    }
    return 0;
  });

  const filteredOrders = sortedOrders.filter((order) => {
    return order.Products.some((product) =>
      product.name.toLowerCase().includes(search.toLowerCase())
    );
  });

  const filteredByDate = filteredOrders.filter((order) => {
    const orderDate = new Date(order.createdAt);
    return (
      (!startDate || orderDate >= startDate) &&
      (!endDate || orderDate <= endDate)
    );
  });

  const itemsPerPage = 5;
  const paginatedOrders = filteredByDate.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredByDate.length / itemsPerPage);

  const renderOrder = (order) => (
    <div
      key={order.id}
      className="border rounded-lg shadow-md p-4 bg-gray-800 text-white hover:shadow-lg transition-shadow duration-300"
      onClick={() => setSelectedOrder(order)}
    >
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold">Order ID: {order.id}</p>
        <div className="flex items-center space-x-2">
          <div className="relative">
            <FaEllipsisV
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(showDropdown === order.id ? null : order.id);
              }}
            />
            {showDropdown === order.id && (
              <div className="absolute right-0 mt-2 w-48 bg-white text-black shadow-lg rounded-lg z-10">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadInvoice(order.id);
                    setShowDropdown(null);
                  }}
                >
                  <FaFileInvoice className="mr-2" /> Download Invoice
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Implement track order functionality
                    setShowDropdown(null);
                  }}
                >
                  <FaTruck className="mr-2" /> Track Order
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCancelOrder(order.id);
                    setShowDropdown(null);
                  }}
                >
                  <FaBan className="mr-2" /> Cancel Order
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Implement reorder functionality
                    setShowDropdown(null);
                  }}
                >
                  <FaRedo className="mr-2" /> Reorder
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-gray-400">
          <strong>Status:</strong>{' '}
          {order.status === 'pending' ? (
            <FaSpinner className="inline mr-1 animate-spin" />
          ) : order.status === 'shipped' ? (
            <FaTruck className="inline mr-1" />
          ) : order.status === 'delivered' ? (
            <FaCheckCircle className="inline mr-1 text-green-500" />
          ) : order.status === 'canceled' ? (
            <FaTimesCircle className="inline mr-1 text-red-500" />
          ) : (
            <FaBox className="inline mr-1" />
          )}
          {order.status}
        </p>
        {order.Products.map((product) => (
          <div key={product.id} className="flex items-center mt-2">
            <img
              src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
              alt={product.name}
              className="w-16 h-16 object-cover rounded-md mr-4"
            />
            <div>
              <p className="text-gray-400">
                <strong>Product:</strong> {product.name} - £
                {product.OrderProduct.price} x {product.OrderProduct.quantity}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-2">
        <label className="block mb-2 text-sm font-medium">Order Notes</label>
        <textarea
          value={orderNotes}
          onChange={(e) => setOrderNotes(e.target.value)}
          placeholder="Add notes..."
          className="w-full p-2 border border-gray-600 rounded mt-1 bg-gray-700 text-white"
        />
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <h1 className="text-4xl font-bold mb-8 text-center">Your Orders</h1>
      <div className="mb-4 flex justify-between items-center bg-gray-800 p-4 rounded">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={handleSearch}
            className="border rounded p-2 bg-gray-700 text-white"
          />
          <FaSearch className="absolute right-2 top-3 text-gray-400" />
        </div>
        <div>
          <label className="mr-2 text-white">Filter by:</label>
          <select
            value={filter}
            onChange={handleFilter}
            className="border rounded p-2 bg-gray-700 text-white"
          >
            <option value="all">All</option>
            <option value="product">Product</option>
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <label className="mr-2 text-white">Sort by:</label>
          <select
            value={sort}
            onChange={(e) => handleSort(e.target.value)}
            className="border rounded p-2 bg-gray-700 text-white"
          >
            <option value="date">Date</option>
            <option value="status">Status</option>
            <option value="price">Price</option>
          </select>
          <div>
            <label className="mr-2 text-white">Date Range:</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="border rounded p-2 bg-gray-700 text-white"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="border rounded p-2 bg-gray-700 text-white"
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Skeleton height={150} count={5} />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-64">
          <p className="text-red-500">{error}</p>
        </div>
      ) : (
        <>
          <div className="mb-8">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setOrdersOpen(!ordersOpen)}
            >
              <h2 className="text-3xl font-semibold mb-4">Orders</h2>
              {ordersOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {ordersOpen && (
              <>
                {paginatedOrders.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {paginatedOrders.map((order) => renderOrder(order))}
                  </div>
                ) : (
                  <p className="text-center text-gray-500">No orders found.</p>
                )}
              </>
            )}
          </div>
          <div className="mt-8 flex justify-center">
            <Pagination
              totalItems={filteredOrders.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}

      {selectedOrder && (
        <Modal
          isOpen={!!selectedOrder}
          onRequestClose={() => setSelectedOrder(null)}
          contentLabel="Order Summary"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <h2 className="text-2xl font-bold mb-4">Order Summary</h2>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Order ID</label>
            <p className="text-lg">{selectedOrder.id}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Status</label>
            <p className="text-lg">{selectedOrder.status}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Products</label>
            {selectedOrder.Products.map((product) => (
              <div key={product.id} className="flex items-center mb-4">
                <img
                  src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
                  alt={product.name}
                  className="w-12 h-12 object-cover rounded-md mr-4"
                />
                <p className="text-lg">
                  {product.name} - £{product.OrderProduct.price} x{' '}
                  {product.OrderProduct.quantity}
                </p>
              </div>
            ))}
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">
              Total Amount
            </label>
            <p className="text-lg">
              £
              {selectedOrder.Products.reduce(
                (total, product) =>
                  total +
                  product.OrderProduct.price * product.OrderProduct.quantity,
                0
              )}
            </p>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setSelectedOrder(null)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
            <button
              onClick={() => handleDownloadInvoice(selectedOrder.id)}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              <FaPrint className="inline mr-2" /> Print Invoice
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Orders;
