import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { FiBell, FiSearch } from 'react-icons/fi';
import Tooltip from "@mui/material/Tooltip";
import { useUser } from "./contexts/UserContext";
import { useCart } from "./contexts/CartContext";
import axios from "axios";
import { useDispatch } from "react-redux";
import { startCartPolling } from "../features/cart/cartSlice";

const MobileNavbar = ({ notifications, addNotification, removeNotification }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { user, logout } = useUser();
  const { cart } = useCart();
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);
  const notificationRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    startCartPolling(dispatch);
  }, [dispatch]);

  const navigation = [
    { name: "Shop", href: "/store" },
    { name: "T&C", href: "/terms-and-conditions" },
    { name: "Contact Us", href: "/contactus" },
  ];

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotificationsOpen]);

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products?search=${query}`);
        if (response.data.length > 0) {
          setSuggestions(response.data);
          setNoMatches(false);
        } else {
          setSuggestions([]);
          setNoMatches(true);
        }
        setShowSuggestions(true);
      } catch (error) {
        console.error("Failed to fetch search results:", error);
        setSuggestions([]);
        setNoMatches(true);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  return (
    <nav className="bg-black text-white flex items-center justify-between p-4">
      <Link to="/" className="text-lg font-bold">
        <img src="/logo.png" alt="xymaturi" className="h-8 w-auto" />
      </Link>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search products"
            className="px-3 py-2 rounded-md text-sm bg-gray-800 text-white"
          />
          <button onClick={() => handleSearch(searchQuery)} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white">
            <FiSearch className="h-5 w-5" />
          </button>
          {showSuggestions && (
            <div className="absolute z-10 mt-1 w-full rounded-md shadow-lg bg-gray-800 text-white">
              {suggestions.map((product) => (
                <div key={product.id} className="cursor-pointer px-3 py-2 hover:bg-gray-300">
                  {product.name}
                </div>
              ))}
              {noMatches && <div className="px-3 py-2">No matches found</div>}
            </div>
          )}
        </div>
        <Tooltip title="Notifications" arrow>
          <div className="relative" ref={notificationRef}>
            <button
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
              className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none relative"
              aria-label="Notifications"
            >
              <FiBell className="h-6 w-6" />
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
              )}
            </button>
            {isNotificationsOpen && (
              <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-black ring-1 ring-black ring-opacity-5 focus:outline-none">
                {notifications.map((notification, index) => (
                  <div key={index} className="px-4 py-2 text-sm text-gray-700 flex justify-between items-center">
                    <span>{notification}</span>
                    <button onClick={() => removeNotification(index)} className="text-red-500 hover:text-red-700 focus:outline-none">
                      <XIcon className="h-4 w-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Tooltip>
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="text-white focus:outline-none">
          {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
        </button>
      </div>
      <Transition
        show={isMobileMenuOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center md:hidden">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-xs">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Menu</h3>
                <button onClick={() => setIsMobileMenuOpen(false)} className="text-gray-800 focus:outline-none">
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-5">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
                {user ? (
                  <div>
                    <Link to="/profile" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                      Profile
                    </Link>
                    <Link to="/orders" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                      Orders
                    </Link>
                    <button onClick={logout} className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
                      Logout
                    </button>
                  </div>
                ) : (
                  <div>
                    <Link to="/login" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                      Login
                    </Link>
                    <Link to="/register" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                      Register
                    </Link>
                  </div>
                )}
                <Link to="/ViewCart" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white" onClick={() => setIsMobileMenuOpen(false)}>
                  🛒 {cartItemCount}
                </Link>
                <Link
                  to="/Supportus#donate"
                  className="block px-3 py-2 mt-2 rounded-md text-base font-medium text-white bg-red-500 hover:bg-red-700 transition duration-150"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Donate
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default MobileNavbar;
