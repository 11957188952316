import React, { useState, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../components/contexts/UserContext';
import { FaChartPie, FaUsers, FaBox, FaBell, FaCog, FaSignOutAlt } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import Overview from '../components/Overview';
import OrdersManagement from '../components/OrdersManagment';
import UsersManagement from '../components/UsersManagement';
import ProductsManagement from '../components/ProductManagement';
import InventoryManagement from '../components/InventoryManagement';
import PaymentsManagement from '../components/PaymentsManagement';
import CryptoPaymentsManagement from '../components/CryptoPaymentsManagement';

const Admin = () => {
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState('overview');
  const [darkMode, setDarkMode] = useState(false);

  if (!user || user.role !== 'admin') {
    return <div>Unauthorized access</div>;
  }

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <Overview />;
      case 'orders':
        return <OrdersManagement />;
      case 'users':
        return <UsersManagement />;
      case 'products':
        return <ProductsManagement />;
      case 'inventory':
        return <InventoryManagement />;
      case 'payments':
        return <PaymentsManagement />;
      case 'cryptoPayments':
        return <CryptoPaymentsManagement />;
      default:
        return <Overview />;
    }
  };

  return (
    <div className={`flex flex-col min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'} transition-all duration-300`}>
      <ToastContainer />
      <TopBar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      <div className="flex flex-grow">
        <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} darkMode={darkMode} />
        <div className="flex-grow p-6 overflow-auto">
          <Suspense fallback={<div>Loading...</div>}>
            {renderContent()}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Admin;
