import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiBell, FiSearch } from 'react-icons/fi';
import Tooltip from '@mui/material/Tooltip';
import { useCart } from './contexts/CartContext';
import { useUser } from './contexts/UserContext';
import { useStore } from './contexts/StoreContext';
import { motion } from 'framer-motion';
import { Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Avatar from 'react-avatar';
import axios from 'axios';

const Navbar = ({ notifications, addNotification, removeNotification }) => {
  const { cart, clearCart, addItemToCart, removeItemFromCart } = useCart();
  const { user, logout, disconnectWeb3, isWeb3User } = useUser();
  const { setSearchResults } = useStore();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [noMatches, setNoMatches] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const notificationRef = useRef();
  const navigate = useNavigate();
  const location = useLocation(); 

  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationsOpen]);

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 2) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products?search=${query}`);
        if (response.data.length > 0) {
          setSuggestions(response.data);
          setNoMatches(false);
        } else {
          setSuggestions([]);
          setNoMatches(true);
        }
        setShowSuggestions(true);
      } catch (error) {
        console.error('Failed to fetch search results:', error);
        setSuggestions([]);
        setNoMatches(true);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelectSuggestion = (product) => {
    setSearchResults([product]);
    setShowSuggestions(false);
    navigate('/store');
  };

  useEffect(() => {
    if (isWeb3User && location.pathname === '/') {
      navigate('/web3-store');
    }
  }, [isWeb3User, location.pathname, navigate]);

  return (
    <nav className="flex items-center relative sticky top-0 z-50 shadow-lg bg-black text-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Left Side: Links */}
          <div className="flex items-center space-x-4">
            <Link
              to={isWeb3User ? "/web3-store" : "/store"}
              className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white transition duration-150"
            >
              {isWeb3User ? "Web3 Shop" : "Shop"}
            </Link>
            <Link
              to="/terms-and-conditions"
              className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white transition duration-150"
            >
              T&C
            </Link>
            <Link
              to="/contactus"
              className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white transition duration-150"
            >
              Contact Us
            </Link>
            {user && user.role === 'admin' && (
              <Link
                to="/admin"
                className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white transition duration-150"
              >
                Admin
              </Link>
            )}
          </div>

          {/* Centered Logo */}
          <div className="mx-auto">
            <Link to="/" className="flex-shrink-0">
              <motion.img src="/logo.png" alt="xymaturi" className="h-15 w-auto" whileHover={{ scale: 1.1 }} />
            </Link>
          </div>

          {/* Right Side: Profile, Cart, Notifications */}
          <div className="flex items-center space-x-5">
            {user ? (
              <UserProfileMenu
                isProfileOpen={isProfileOpen}
                setIsProfileOpen={setIsProfileOpen}
                logout={isWeb3User ? disconnectWeb3 : logout}
                user={user}
              />
            ) : (
              <GuestMenu />
            )}
            <CartDropdown
              isCartDropdownOpen={isCartDropdownOpen}
              setIsCartDropdownOpen={setIsCartDropdownOpen}
              cart={cart}
              cartItemCount={cartItemCount}
              addItemToCart={addItemToCart}
              removeItemFromCart={removeItemFromCart}
              clearCart={clearCart}
            />
            <Tooltip title="Notifications" arrow>
              <div className="relative" ref={notificationRef}>
                <button 
                  onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                  className="p-1 rounded-full text-white hover:text-white focus:outline-none relative" aria-label="Notifications">
                  <FiBell className="h-6 w-6" />
                  {notifications.length > 0 && (
                    <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
                  )}
                </button>
                <Transition
                  show={isNotificationsOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-black text-white">
                    {notifications.map((notification, index) => (
                      <div key={index} className="px-4 py-2 text-sm flex justify-between items-center">
                        <span>{notification}</span>
                        <button onClick={() => removeNotification(index)} className="text-red-500 hover:text-red-700 focus:outline-none">
                          <XIcon className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </Transition>
              </div>
            </Tooltip>
            <div className="relative">
              <button
                onClick={() => setIsSearchOpen(!isSearchOpen)}
                className="p-1 rounded-full text-white hover:text-white focus:outline-none"
                aria-label="Open Search"
              >
                <FiSearch className="h-6 w-6" />
              </button>
              {isSearchOpen && (
                <div className="absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-gray-800 text-white">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search products"
                    className="w-full px-3 py-2 rounded-md text-sm bg-gray-800 text-white"
                  />
                  {showSuggestions && (
                    <div className="absolute z-10 mt-1 w-full rounded-md shadow-lg bg-gray-800 text-white">
                      {suggestions.map((product) => (
                        <div
                          key={product.id}
                          className="cursor-pointer px-3 py-2 hover:bg-gray-300"
                          onClick={() => handleSelectSuggestion(product)}
                        >
                          {product.name}
                        </div>
                      ))}
                      {noMatches && <div className="px-3 py-2">No matches found</div>}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <MobileMenuButton
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </div>
      </div>

      <Transition show={isMobileMenuOpen} enter="transition ease-out duration-100 transform" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <div className="md:hidden border-t bg-black border-gray-700" id="mobile-menu">
          <Link to={isWeb3User ? "/web3-store" : "/store"} className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white transition duration-150 text-white">
            {isWeb3User ? "Web3 Shop" : "Shop"}
          </Link>
          <Link to="/terms-and-conditions" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white transition duration-150 text-white">
            T&C
          </Link>
          <Link to="/contactus" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white transition duration-150 text-white">
            Contact Us
          </Link>
          {user && user.role === 'admin' && (
            <Link to="/admin" className="block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white transition duration-150 text-white">
              Admin
            </Link>
          )}
        </div>
      </Transition>
    </nav>
  );
};

const UserProfileMenu = ({ isProfileOpen, setIsProfileOpen, logout, user }) => {
  return (
    <div className="relative">
      <button onClick={() => setIsProfileOpen(!isProfileOpen)} className="focus:outline-none text-white hover:text-white" aria-label="Open user menu">
        <span className="sr-only">Open user menu</span>
        {user.avatar ? (
          <Avatar src={user.avatar} size="32" round className="h-8 w-8" />
        ) : (
          <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100 text-center font-medium">
            {user.username ? user.username[0] : user.web3Address?.substring(0, 2).toUpperCase()}
          </span>
        )}
      </button>
      <Transition
        show={isProfileOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-black text-white">
          <Link to={user.web3Address ? "/web3-profile" : "/profile"} className="block px-4 py-2 text-sm hover:bg-gray-100">
            {user.web3Address ? "Web3 Profile" : "Profile"}
          </Link>
          <Link to={user.web3Address ? "/web3-orders" : "/orders"} className="block px-4 py-2 text-sm hover:bg-gray-100">
            {user.web3Address ? "Web3 Orders" : "Orders"}
          </Link>
          <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100">
            Logout
          </button>
        </div>
      </Transition>
    </div>
  );
};

const GuestMenu = () => (
  <div className="flex space-x-4">
    <Link to="/login" className="font-bold py-2 px-4 transition duration-150">Login</Link>
    <Link to="/register" className="font-bold py-2 px-4 transition duration-150">Register</Link>
  </div>
);

const CartItem = ({ item, addItemToCart, removeItemFromCart }) => {
  return (
    <div key={item.id} className="px-4 py-2 text-sm flex items-center justify-between">
      <img src={item.product?.imageUrl || '/default-image.jpg'} alt={item.product?.name} className="h-10 w-10 object-cover rounded-full mr-4" />
      <span>{item.product?.name}</span>
      <div className="flex items-center">
        <button onClick={() => removeItemFromCart(item.id)} className="px-2 py-1 hover:bg-gray-300 rounded transition duration-150">-</button>
        <span className="px-2">{item.quantity}</span>
        <button onClick={() => addItemToCart(item)} className="px-2 py-1 hover:bg-gray-300 rounded transition duration-150">+</button>
      </div>
    </div>
  );
};

const CartDropdown = ({ isCartDropdownOpen, setIsCartDropdownOpen, cart, cartItemCount, addItemToCart, removeItemFromCart, clearCart }) => {
  return (
    <div
      className="relative"
      onMouseEnter={() => setIsCartDropdownOpen(true)}
      onMouseLeave={() => setIsCartDropdownOpen(false)}
    >
      <Tooltip title="View Cart" arrow>
        <button className="p-1 rounded-full text-white hover:text-white focus:outline-none" aria-label="View Cart">
          <span className="sr-only">View Cart</span>
          <span className="inline-block h-8 w-8 text-center font-medium relative">
            🛒 {cartItemCount}
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
            )}
          </span>
        </button>
      </Tooltip>
      <Transition
        show={isCartDropdownOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none bg-black text-white">
          {cart.length > 0 ? (
            cart.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                addItemToCart={addItemToCart}
                removeItemFromCart={removeItemFromCart}
              />
            ))
          ) : (
            <div className="px-4 py-2 text-sm">Your cart is empty</div>
          )}
          <Link to="/ViewCart" className="block px-4 py-2 text-sm hover:bg-gray-100">
            View Cart
          </Link>
          <button onClick={clearCart} className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100">
            Clear Cart
          </button>
        </div>
      </Transition>
    </div>
  );
};

const MobileMenuButton = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => (
  <div className="-mr-2 flex md:hidden">
    <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none" aria-label="Toggle Mobile Menu">
      <span className="sr-only">Open main menu</span>
      {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
    </button>
  </div>
);

export default Navbar;
