import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
import StatusFilter from './StatusFilter';
import { FaEdit, FaTrashAlt, FaSave, FaTimes, FaUserPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all');
  const [sortField, setSortField] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [editedUsers, setEditedUsers] = useState({});
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const editUser = (userId) => {
    setEditMode({ ...editMode, [userId]: true });
    setEditedUsers(users.find(user => user.userId === userId));
  };

  const saveUser = async (userId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/${userId}`, editedUsers, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(users.map(user => (user.userId === userId ? editedUsers : user)));
      setEditMode({ ...editMode, [userId]: false });
      toast.success('User updated successfully');
    } catch (error) {
      console.error('Failed to update user:', error);
      toast.error('Failed to update user');
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(users.filter(user => user.userId !== userId));
      toast.success('User deleted successfully');
    } catch (error) {
      console.error('Failed to delete user:', error);
      toast.error('Failed to delete user');
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (status) => {
    setFilter(status);
  };

  const handleSort = (field) => {
    const order = field === sortField && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers(
      selectedUsers.includes(userId)
        ? selectedUsers.filter(id => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleMultiDelete = async () => {
    try {
      await Promise.all(selectedUsers.map(userId => deleteUser(userId)));
      setSelectedUsers([]);
      toast.success('Selected users deleted successfully');
    } catch (error) {
      console.error('Failed to delete selected users:', error);
      toast.error('Failed to delete selected users');
    }
  };

  const handleUserEditChange = (userId, field, value) => {
    setEditedUsers({
      ...editedUsers,
      [field]: value,
    });
  };

  const filteredUsers = users
    .filter(user => user.username.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(user => filter === 'all' || user.role === filter)
    .sort((a, b) => {
      const order = sortOrder === 'asc' ? 1 : -1;
      return a[sortField] > b[sortField] ? order : -order;
    });

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Users</h1>
      <div className="mb-4 flex justify-between">
        <input
          type="text"
          placeholder="Search by username"
          value={searchQuery}
          onChange={handleSearch}
          className="border rounded p-2 bg-gray-200"
        />
        <StatusFilter filter={filter} onChange={handleFilterChange} />
      </div>
      {selectedUsers.length > 0 && (
        <div className="mb-4 flex justify-between">
          <button
            onClick={handleMultiDelete}
            className="bg-red-500 text-white py-2 px-4 rounded"
          >
            Delete Selected ({selectedUsers.length})
          </button>
        </div>
      )}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('userId')}>
              User ID {sortField === 'userId' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('username')}>
              Username {sortField === 'username' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('email')}>
              Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th className="py-2 px-4 border-b cursor-pointer" onClick={() => handleSort('role')}>
              Role {sortField === 'role' && (sortOrder === 'asc' ? '↑' : '↓')}
            </th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user) => (
            <tr key={user.userId} className={`${selectedUsers.includes(user.userId) ? 'bg-gray-100' : ''}`}>
              <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.userId)}
                  onChange={() => handleSelectUser(user.userId)}
                />
              </td>
              <td className="py-2 px-4 border-b">{user.userId}</td>
              <td className="py-2 px-4 border-b">
                {editMode[user.userId] ? (
                  <input
                    type="text"
                    value={editedUsers.username}
                    onChange={(e) => handleUserEditChange(user.userId, 'username', e.target.value)}
                    className="border rounded p-2 bg-gray-100"
                  />
                ) : (
                  user.username
                )}
              </td>
              <td className="py-2 px-4 border-b">
                {editMode[user.userId] ? (
                  <input
                    type="email"
                    value={editedUsers.email}
                    onChange={(e) => handleUserEditChange(user.userId, 'email', e.target.value)}
                    className="border rounded p-2 bg-gray-100"
                  />
                ) : (
                  user.email
                )}
              </td>
              <td className="py-2 px-4 border-b">
                {editMode[user.userId] ? (
                  <select
                    value={editedUsers.role}
                    onChange={(e) => handleUserEditChange(user.userId, 'role', e.target.value)}
                    className="border rounded p-2 bg-gray-100"
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                ) : (
                  user.role
                )}
              </td>
              <td className="py-2 px-4 border-b">
                {editMode[user.userId] ? (
                  <>
                    <button
                      onClick={() => saveUser(user.userId)}
                      className="bg-green-500 text-white py-1 px-2 rounded mr-2"
                    >
                      <FaSave />
                    </button>
                    <button
                      onClick={() => setEditMode({ ...editMode, [user.userId]: false })}
                      className="bg-gray-500 text-white py-1 px-2 rounded"
                    >
                      <FaTimes />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => editUser(user.userId)}
                      className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => deleteUser(user.userId)}
                      className="bg-red-500 text-white py-1 px-2 rounded"
                    >
                      <FaTrashAlt />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalItems={filteredUsers.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default UsersManagement;
