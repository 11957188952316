import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../../api';
import Web3 from 'web3';

const UserContext = createContext({
  user: null,
  token: null,
  login: () => {},
  logout: () => {},
  isWeb3User: false,
  updateUser: () => {},
  initializeWeb3: () => {},
  disconnectWeb3: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isWeb3User, setIsWeb3User] = useState(false);
  const [web3, setWeb3] = useState(null);

  const fetchUserData = useCallback(async () => {
    if (!token) return;
    try {
      const response = await api.get('/users/current_user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setUser(response.data);
        setIsWeb3User(localStorage.getItem('isWeb3User') === 'true');
      }
    } catch (error) {
      console.error('Failed to fetch user:', error);
      setUser(null);
    }
  }, [token]);

  useEffect(() => {
    if (token && !user) {
      fetchUserData();
    }
  }, [token, fetchUserData, user]);

  const login = async ({ email, password }) => {
    try {
      const response = await api.post('/users/login', { email, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token);
        setUser(response.data.user);
        setIsWeb3User(false); 
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error);
      throw error; 
    }
  };

  const loginWithWeb3 = async (address) => {
    try {
      const response = await api.post('/users/web3-login', { address });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('isWeb3User', 'true');
        setToken(response.data.token);
        setUser(response.data.user);
        setIsWeb3User(true);
      } else {
        throw new Error('Web3 login failed');
      }
    } catch (error) {
      console.error('Web3 login failed:', error);
      throw error;
    }
  };

  const updateUser = async (details) => {
    try {
      const response = await api.put(`/users/${user.userId}`, details, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isWeb3User');
    setToken(null);
    setUser(null);
    setIsWeb3User(false);
  };

  const disconnectWeb3 = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        logout();
      } catch (error) {
        console.error('Failed to disconnect Web3:', error);
      }
    }
  };

  const initializeWeb3 = useCallback(async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3Instance.eth.getAccounts();
        if (accounts.length > 0) {
          await loginWithWeb3(accounts[0]);
        }
        setWeb3(web3Instance);
      } catch (error) {
        console.error('Failed to connect with Web3:', error);
      }
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ user, token, login, logout, isWeb3User, updateUser, initializeWeb3, disconnectWeb3 }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserContext;
