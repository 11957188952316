import React from 'react';
import { FaTiktok, FaInstagram } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-black text-gray-100 p-6">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-3 gap-4 items-center">
        {/* Contact Information */}
        <div className="text-center sm:text-left">
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center gap-4">
          <a href="https://www.tiktok.com/@xymatauri" target="_blank" rel="noopener noreferrer" className="hover:text-pink-600">
            <FaTiktok />
          </a>
          <a href="https://www.instagram.com/proximatauri/" target="_blank" rel="noopener noreferrer" className="hover:text-pink-600">
            <FaInstagram />
          </a>
        </div>

        {/* Google Translate Element */}
        <div className="text-center sm:text-right">
          <div id="google_translate_element" className="inline-block"></div>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="flex justify-center mt-4">
        <img src="/Media/Images/payments.png" alt="Payment Methods" className="w-80" />
      </div>

      {/* Copyright Notice */}
      <div className="text-center text-gray-400 text-sm mt-4">
        © 2024, Proxima Centauri, Proudly created by 
        <a href="https://quantaum.co.uk" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300"> Quantaum</a>.
      </div>
    </footer>
  );
}

export default Footer;
