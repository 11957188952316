import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import {
    FaTrash, FaEdit, FaSave, FaSort, FaCheck, FaPlusCircle,
    FaUpload, FaChartLine
} from 'react-icons/fa';
import BulkActionModal from './BulkActionModal';
import ExportProducts from './ExportProducts';
import Dashboard from './Dashboard';
import AddProductForm from './AddProductForm';
import EditProductForm from './EditProductForm';
import AIPricingRecommendations from './AIPricingRecommendations';
import io from 'socket.io-client';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ProductsManagement = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [bulkAction, setBulkAction] = useState('');
    const [filters, setFilters] = useState({ category: '', priceRange: '', stockStatus: '', search: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showBulkActionModal, setShowBulkActionModal] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAIModalOpen, setIsAIModalOpen] = useState(false);

    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io(process.env.REACT_APP_API_URL, {
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        const socket = socketRef.current;

        socket.on('connect', () => {
            console.log('Connected to the server');
        });

        socket.on('productUpdate', () => fetchProducts());

        socket.on('disconnect', () => {
            console.log('Disconnected from the server');
        });

        socket.on('error', (err) => {
            console.error('Socket error:', err);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [filters, sortConfig, currentPage]);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
                params: { filters, sortConfig, page: currentPage, itemsPerPage },
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            console.log('Fetched Products:', response.data);
            setProducts(response.data || []);
            setDashboardData(response.data.dashboardData || {});
        } catch (error) {
            console.error('Failed to fetch products:', error);
        }
    };

    const handleEdit = (product) => {
        setSelectedProduct(product);
        setIsEditProductModalOpen(true);
    };

    const handleSave = async (updatedProduct) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/products/${updatedProduct.id}`, updatedProduct, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setEditingProductId(null);
            fetchProducts();
            socketRef.current.emit('productUpdated', updatedProduct);
            setIsEditProductModalOpen(false);
        } catch (error) {
            console.error('Failed to save product:', error);
        }
    };

    const handleDelete = async (productId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/products/${productId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setProducts(products.filter(product => product.id !== productId));
            socketRef.current.emit('productDeleted', productId);
        } catch (error) {
            console.error('Failed to delete product:', error);
        }
    };

    const handleBulkAction = () => {
        setShowBulkActionModal(true);
    };

    const applyBulkAction = async (action) => {
        setShowBulkActionModal(false);
        socketRef.current.emit('bulkAction', { action, products: selectedProducts });
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleFilterChange = (filterKey, value) => {
        setFilters({ ...filters, [filterKey]: value });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAIRecommendation = useCallback(() => {
        if (!selectedProduct) {
            alert("Please select a product to get AI recommendations.");
            return;
        }
        setIsAIModalOpen(true);
    }, [selectedProduct]);

    const handleProductAdded = (newProduct) => {
        setProducts([newProduct, ...products]);
        setIsAddProductModalOpen(false);
    };

    const handleProductEdited = (editedProduct) => {
        setProducts(products.map(p => p.id === editedProduct.id ? editedProduct : p));
        setIsEditProductModalOpen(false);
    };

    const handleRowClick = (product) => {
        setSelectedProduct(product);
    };

    return (
        <div className="ml-64 p-6">
            <h1 className="text-3xl font-bold mb-4">Manage Products</h1>

            <Dashboard data={dashboardData} />

            <div className="flex justify-between mb-4">
                <div className="flex space-x-4">
                    <button onClick={handleBulkAction} className="bg-blue-500 text-white py-2 px-4 rounded">
                        <FaCheck /> Bulk Actions
                    </button>
                    <button onClick={() => setIsAddProductModalOpen(true)} className="bg-green-500 text-white py-2 px-4 rounded">
                        <FaPlusCircle /> Add New Product
                    </button>
                    <ExportProducts products={products} />
                    <button className="bg-purple-500 text-white py-2 px-4 rounded">
                        <FaUpload /> Import CSV
                    </button>
                </div>
                <div className="flex space-x-4">
                    <input
                        type="text"
                        placeholder="Search products..."
                        className="p-2 border border-gray-400 rounded"
                        onChange={(e) => handleFilterChange('search', e.target.value)}
                    />
                    <button onClick={() => handleSort('name')} className="bg-gray-200 text-black py-2 px-4 rounded">
                        <FaSort /> Sort by Name
                    </button>
                    <button onClick={() => handleSort('price')} className="bg-gray-200 text-black py-2 px-4 rounded">
                        <FaSort /> Sort by Price
                    </button>
                    <button onClick={handleAIRecommendation} className="bg-indigo-500 text-white py-2 px-4 rounded">
                        <FaChartLine /> AI Recommendations
                    </button>
                </div>
            </div>

            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">
                            <input
                                type="checkbox"
                                checked={selectedProducts.length === products.length}
                                onChange={(e) => setSelectedProducts(e.target.checked ? products.map(p => p.id) : [])}
                            />
                        </th>
                        <th className="py-2 px-4 border-b">Product ID</th>
                        <th className="py-2 px-4 border-b">Name</th>
                        <th className="py-2 px-4 border-b">Category</th>
                        <th className="py-2 px-4 border-b">Price</th>
                        <th className="py-2 px-4 border-b">Stock Quantity</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.length > 0 ? (
                        products.map((product) => (
                            <tr key={product.id} onClick={() => handleRowClick(product)} className={selectedProduct?.id === product.id ? 'bg-gray-200' : ''}>
                                <td className="py-2 px-4 border-b">
                                    <input
                                        type="checkbox"
                                        checked={selectedProducts.includes(product.id)}
                                        onChange={(e) => setSelectedProducts(e.target.checked ? [...selectedProducts, product.id] : selectedProducts.filter(id => id !== product.id))}
                                    />
                                </td>
                                <td className="py-2 px-4 border-b">{product.id}</td>
                                <td className="py-2 px-4 border-b">
                                    {editingProductId === product.id ? (
                                        <input
                                            type="text"
                                            value={product.name}
                                            onChange={(e) => handleSave(product.id, { ...product, name: e.target.value })}
                                        />
                                    ) : (
                                        product.name
                                    )}
                                </td>
                                <td className="py-2 px-4 border-b">{product.category}</td>
                                <td className="py-2 px-4 border-b">£{product.price}</td>
                                <td className="py-2 px-4 border-b">{product.stockQuantity}</td>
                                <td className="py-2 px-4 border-b">
                                    <>
                                        <button onClick={() => handleEdit(product)} className="bg-blue-500 text-white py-1 px-2 rounded">
                                            <FaEdit /> Edit
                                        </button>
                                        <button onClick={() => handleDelete(product.id)} className="bg-red-500 text-white py-1 px-2 rounded ml-2">
                                            <FaTrash /> Delete
                                        </button>
                                    </>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="text-center py-4">
                                No products found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="flex justify-between mt-4">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="bg-gray-500 text-white py-2 px-4 rounded">
                    Previous
                </button>
                <button onClick={() => handlePageChange(currentPage + 1)} className="bg-gray-500 text-white py-2 px-4 rounded">
                    Next
                </button>
            </div>

            {showBulkActionModal && (
                <BulkActionModal
                    selectedProducts={selectedProducts}
                    onApply={applyBulkAction}
                    onClose={() => setShowBulkActionModal(false)}
                />
            )}

            {isAddProductModalOpen && (
                <Modal
                    isOpen={isAddProductModalOpen}
                    onRequestClose={() => setIsAddProductModalOpen(false)}
                    contentLabel="Add Product"
                    className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
                >
                    <AddProductForm
                        onClose={() => setIsAddProductModalOpen(false)}
                        onProductAdded={handleProductAdded}
                    />
                </Modal>
            )}

            {isEditProductModalOpen && selectedProduct && (
                <Modal
                    isOpen={isEditProductModalOpen}
                    onRequestClose={() => setIsEditProductModalOpen(false)}
                    contentLabel="Edit Product"
                    className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
                >
                    <EditProductForm
                        product={selectedProduct}
                        onClose={() => setIsEditProductModalOpen(false)}
                        onProductEdited={handleProductEdited}
                    />
                </Modal>
            )}

            {isAIModalOpen && selectedProduct && (
                <Modal
                    isOpen={isAIModalOpen}
                    onRequestClose={() => setIsAIModalOpen(false)}
                    contentLabel="AI Recommendations"
                    className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
                >
                    <AIPricingRecommendations
                        productId={selectedProduct.id}
                        currentPrice={selectedProduct.price}
                        onClose={() => setIsAIModalOpen(false)}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ProductsManagement;
