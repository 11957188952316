import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import OrderDetails from './OrderDetails';
import { FaEye, FaTrash, FaSearch, FaSpinner, FaFilter, FaCheckSquare, FaFileDownload, FaBell, FaEnvelope, FaEdit, FaSave, FaListAlt } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from './Pagination';
import StatusFilter from './StatusFilter';
import OrderStats from './OrderStats';

const OrdersManagement = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const refreshInterval = useRef(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch orders:', error);
        toast.error('Failed to fetch orders');
        setLoading(false);
      }
    };

    fetchOrders();

    // Auto-refresh orders every 5 minutes
    refreshInterval.current = setInterval(fetchOrders, 300000);

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterStatus = (event) => {
    setFilterStatus(event.target.value);
  };
  const filteredOrders = orders.filter((order) => {
    const customerName = order.customerName ? order.customerName.toLowerCase() : '';
    return (
      customerName.includes(searchTerm.toLowerCase()) &&
      (filterStatus === 'all' || order.status === filterStatus)
    );
  });
  

  const paginatedOrders = filteredOrders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const viewOrder = (orderId) => {
    setSelectedOrder(orderId);
  };

  const deleteOrder = async (orderId) => {
    if (!window.confirm('Are you sure you want to delete this order?')) return;

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setOrders(orders.filter((order) => order.id !== orderId));
      toast.success('Order deleted successfully');
    } catch (error) {
      console.error('Failed to delete order:', error);
      toast.error('Failed to delete order');
    }
  };

  const toggleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleBulkAction = async () => {
    if (!bulkAction || selectedOrders.length === 0) return;
    
    try {
      if (bulkAction === 'delete') {
        await Promise.all(
          selectedOrders.map((orderId) =>
            axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
          )
        );
        setOrders(orders.filter((order) => !selectedOrders.includes(order.id)));
        toast.success('Selected orders deleted successfully');
      } else {
        await Promise.all(
          selectedOrders.map((orderId) =>
            axios.put(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}`, {
              status: bulkAction,
            }, {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
          )
        );
        setOrders(
          orders.map((order) =>
            selectedOrders.includes(order.id) ? { ...order, status: bulkAction } : order
          )
        );
        toast.success(`Selected orders updated to ${bulkAction} successfully`);
      }
      setSelectedOrders([]);
    } catch (error) {
      console.error('Failed to perform bulk action:', error);
      toast.error('Failed to perform bulk action');
    }
  };

  const downloadInvoice = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}/invoice`, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoice_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to download invoice:', error);
      toast.error('Failed to download invoice');
    }
  };

  const sendNotification = async (orderId, type) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/orders/${orderId}/notify`, { type }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      toast.success(`Notification sent successfully via ${type}`);
    } catch (error) {
      console.error(`Failed to send ${type} notification:`, error);
      toast.error(`Failed to send ${type} notification`);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <OrderStats orders={orders} />
      <h1 className="text-3xl font-bold mb-4">Manage Orders</h1>
      
      <div className="mb-4 flex justify-between items-center">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search by customer name..."
            value={searchTerm}
            onChange={handleSearch}
            className="p-2 border border-gray-400 rounded w-full"
          />
          <FaSearch className="absolute right-2 top-3 text-gray-400" />
        </div>
        <StatusFilter filterStatus={filterStatus} handleFilterStatus={handleFilterStatus} />
        <select
          value={bulkAction}
          onChange={(e) => setBulkAction(e.target.value)}
          className="ml-4 p-2 border border-gray-400 rounded"
        >
          <option value="">Bulk Actions</option>
          <option value="delete">Delete</option>
          <option value="processed">Mark as Processed</option>
          <option value="shipped">Mark as Shipped</option>
          <option value="delivered">Mark as Delivered</option>
        </select>
        <button
          onClick={handleBulkAction}
          className="ml-2 p-2 bg-blue-500 text-white rounded"
        >
          Apply
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-4xl text-gray-500" />
        </div>
      ) : (
        <table className="min-w-full bg-white border-collapse block md:table">
          <thead className="block md:table-header-group">
            <tr className="border border-gray-300 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto md:relative">
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">
                <input
                  type="checkbox"
                  checked={selectedOrders.length === orders.length}
                  onChange={() => setSelectedOrders(selectedOrders.length === orders.length ? [] : orders.map((order) => order.id))}
                />
              </th>
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">Order ID</th>
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">Customer</th>
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">Total</th>
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">Status</th>
              <th className="p-2 text-gray-600 font-bold md:border md:border-gray-300 text-left block md:table-cell">Actions</th>
            </tr>
          </thead>
          <tbody className="block md:table-row-group">
            {paginatedOrders.map((order) => (
              <tr key={order.id} className="bg-gray-100 border border-gray-300 md:border-none block md:table-row">
                <td className="p-2 md:border md:border-gray-300 text-left block md:table-cell">
                  <input
                    type="checkbox"
                    checked={selectedOrders.includes(order.id)}
                    onChange={() => toggleSelectOrder(order.id)}
                  />
                </td>
                <td className="p-2 md:border md:border-gray-300 text-left block md:table-cell">{order.id}</td>
                <td className="p-2 md:border md:border-gray-300 text-left block md:table-cell">{order.customerName}</td>
                <td className="p-2 md:border md:border-gray-300 text-left block md:table-cell">£{order.total}</td>
                <td className={`p-2 md:border md:border-gray-300 text-left block md:table-cell ${getOrderStatusClass(order.status)}`}>
                  {order.status}
                </td>
                <td className="p-2 md:border md:border-gray-300 text-left block md:table-cell">
                  <button
                    onClick={() => viewOrder(order.id)}
                    className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
                  >
                    <FaEye /> View
                  </button>
                  <button
                    onClick={() => deleteOrder(order.id)}
                    className="bg-red-500 text-white py-1 px-2 rounded mr-2"
                  >
                    <FaTrash /> Delete
                  </button>
                  <button
                    onClick={() => downloadInvoice(order.id)}
                    className="bg-green-500 text-white py-1 px-2 rounded mr-2"
                  >
                    <FaFileDownload /> Invoice
                  </button>
                  <button
                    onClick={() => sendNotification(order.id, 'email')}
                    className="bg-yellow-500 text-white py-1 px-2 rounded mr-2"
                  >
                    <FaEnvelope /> Email
                  </button>
                  {/* Remove SMS button if you no longer need SMS notifications */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        totalItems={filteredOrders.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {selectedOrder && (
        <div className="mt-8">
          <OrderDetails orderId={selectedOrder} onClose={() => setSelectedOrder(null)} />
        </div>
      )}
    </div>
  );
};

const getOrderStatusClass = (status) => {
  switch (status) {
    case 'pending':
      return 'text-yellow-500';
    case 'processed':
      return 'text-blue-500';
    case 'shipped':
      return 'text-purple-500';
    case 'delivered':
      return 'text-green-500';
    default:
      return '';
  }
};

export default OrdersManagement;
