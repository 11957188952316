// src/Pages/HomePage.js
import React from 'react';
import SplashPage from '../components/SplashPage';

const HomePage = () => {
  return (
    <div>
      <SplashPage />
    </div>
  );
};

export default HomePage;
