import React from 'react';
import { FaFileExport } from 'react-icons/fa';

const ExportProducts = ({ products }) => {
  const handleExport = () => {
    const csvContent = "data:text/csv;charset=utf-8," 
      + products.map(p => `${p.id},${p.name},${p.category},${p.price},${p.stockQuantity}`).join("\n");
    
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "products.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <button onClick={handleExport} className="bg-blue-500 text-white py-2 px-4 rounded">
      <FaFileExport /> Export Products
    </button>
  );
};

export default ExportProducts;
