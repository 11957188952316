import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FaSearch,
  FaSort,
  FaSync,
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaDownload,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';

Modal.setAppElement('#root');

const PaymentsManagement = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ search: '', status: '', sort: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [refundReason, setRefundReason] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/stripe/payments`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: { ...filters, page: currentPage, limit: itemsPerPage, startDate, endDate },
        });
        setPayments(response.data.payments);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch payments:', error);
        setError('Failed to load payments.');
        setLoading(false);
      }
    };

    fetchPayments();
  }, [filters, currentPage, itemsPerPage, startDate, endDate]);

  const handleSearchChange = (e) => setFilters({ ...filters, search: e.target.value });

  const handleSortChange = (sortKey) => {
    let direction = 'asc';
    if (filters.sort === `${sortKey}_asc`) {
      direction = 'desc';
    }
    setFilters({ ...filters, sort: `${sortKey}_${direction}` });
  };

  const handleStatusChange = (status) => setFilters({ ...filters, status });

  const handleRefund = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/stripe/refund`,
        { paymentIntentId: selectedPayment.paymentIntentId, reason: refundReason },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      if (response.data.success) {
        toast.success('Refund processed successfully!');
        setIsRefundModalOpen(false);
        setRefundReason('');
        setSelectedPayment(null);
        setFilters({ ...filters }); // Trigger a re-fetch of the payments
      } else {
        toast.error('Failed to process refund.');
      }
    } catch (error) {
      console.error('Failed to process refund:', error);
      toast.error('Failed to process refund.');
    }
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const openRefundModal = (payment) => {
    setSelectedPayment(payment);
    setIsRefundModalOpen(true);
  };

  const openDetailModal = (payment) => {
    setSelectedPayment(payment);
    setIsDetailModalOpen(true);
  };

  const togglePaymentSelection = (paymentId) => {
    if (selectedPayments.includes(paymentId)) {
      setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
    } else {
      setSelectedPayments([...selectedPayments, paymentId]);
    }
  };

  const handleBulkRefund = async () => {
    if (selectedPayments.length === 0) {
      toast.error('Please select at least one payment for a bulk refund.');
      return;
    }

    try {
      for (let paymentId of selectedPayments) {
        const payment = payments.find((p) => p.id === paymentId);
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/stripe/refund`,
          { paymentIntentId: payment.paymentIntentId, reason: 'Bulk refund' },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
      }

      toast.success('Bulk refund processed successfully!');
      setSelectedPayments([]);
      setFilters({ ...filters }); // Trigger a re-fetch of the payments
    } catch (error) {
      console.error('Failed to process bulk refund:', error);
      toast.error('Failed to process bulk refund.');
    }
  };

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/stripe/payments/export`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { ...filters },
      });

      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'payments.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Payments exported successfully!');
    } catch (error) {
      console.error('Failed to export payments:', error);
      toast.error('Failed to export payments.');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Payments</h1>
      <div className="mb-4 flex justify-between">
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search by User or Payment ID..."
            className="p-2 border border-gray-400 rounded"
            value={filters.search}
            onChange={handleSearchChange}
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            className="p-2 border border-gray-400 rounded"
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            className="p-2 border border-gray-400 rounded"
          />
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded ${filters.sort.includes('amount') && 'bg-blue-700'}`}
            onClick={() => handleSortChange('amount')}
          >
            <FaSort /> Sort by Amount
          </button>
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded ${filters.sort.includes('createdAt') && 'bg-blue-700'}`}
            onClick={() => handleSortChange('createdAt')}
          >
            <FaSort /> Sort by Date
          </button>
          <select
            value={filters.status}
            onChange={(e) => handleStatusChange(e.target.value)}
            className="p-2 border border-gray-400 rounded"
          >
            <option value="">All Statuses</option>
            <option value="succeeded">Succeeded</option>
            <option value="pending">Pending</option>
            <option value="failed">Failed</option>
            <option value="refunded">Refunded</option>
          </select>
        </div>
        <div className="flex space-x-4">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded"
            onClick={handleExport}
            disabled={isExporting}
          >
            {isExporting ? <FaSpinner className="animate-spin" /> : <FaDownload />} Export Payments
          </button>
          <button
            className="bg-red-500 text-white py-2 px-4 rounded"
            onClick={handleBulkRefund}
            disabled={selectedPayments.length === 0}
          >
            <FaTimesCircle /> Bulk Refund
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-3xl text-blue-500" />
        </div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selectedPayments.length === payments.length}
                    onChange={(e) =>
                      setSelectedPayments(e.target.checked ? payments.map((p) => p.id) : [])
                    }
                  />
                </th>
                <th className="py-2 px-4 border-b">Payment ID</th>
                <th className="py-2 px-4 border-b">User</th>
                <th className="py-2 px-4 border-b">Amount</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selectedPayments.includes(payment.id)}
                      onChange={() => togglePaymentSelection(payment.id)}
                    />
                  </td>
                  <td className="py-2 px-4 border-b">{payment.paymentIntentId}</td>
                  <td className="py-2 px-4 border-b">{payment.user.email}</td>
                  <td className="py-2 px-4 border-b">£{payment.amount}</td>
                  <td className="py-2 px-4 border-b">{payment.status}</td>
                  <td className="py-2 px-4 border-b flex space-x-2">
                    <button
                      onClick={() => openDetailModal(payment)}
                      className="bg-blue-500 text-white py-1 px-2 rounded"
                    >
                      Details
                    </button>
                    {payment.status !== 'refunded' && (
                      <button
                        onClick={() => openRefundModal(payment)}
                        className="bg-red-500 text-white py-1 px-2 rounded"
                      >
                        Refund
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-4 flex justify-between">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              <FaChevronLeft /> Previous
            </button>
            <div className="flex items-center">
              Page {currentPage} of {totalPages}
            </div>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Next <FaChevronRight />
            </button>
          </div>
        </>
      )}

      {isDetailModalOpen && (
        <Modal
          isOpen={isDetailModalOpen}
          onRequestClose={() => setIsDetailModalOpen(false)}
          contentLabel="Payment Details"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <h2 className="text-2xl font-bold mb-4">Payment Details</h2>
          <p><strong>Payment ID:</strong> {selectedPayment.paymentIntentId}</p>
          <p><strong>User:</strong> {selectedPayment.user.email}</p>
          <p><strong>Amount:</strong> £{selectedPayment.amount}</p>
          <p><strong>Status:</strong> {selectedPayment.status}</p>
          <p><strong>Date:</strong> {new Date(selectedPayment.createdAt).toLocaleString()}</p>
          <p><strong>Receipt URL:</strong> <a href={selectedPayment.receiptUrl} target="_blank" rel="noopener noreferrer">View Receipt</a></p>
          <button
            onClick={() => setIsDetailModalOpen(false)}
            className="bg-gray-500 text-white py-2 px-4 rounded mt-4"
          >
            Close
          </button>
        </Modal>
      )}

      {isRefundModalOpen && (
        <Modal
          isOpen={isRefundModalOpen}
          onRequestClose={() => setIsRefundModalOpen(false)}
          contentLabel="Process Refund"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <h2 className="text-2xl font-bold mb-4">Process Refund</h2>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Refund Reason</label>
            <textarea
              value={refundReason}
              onChange={(e) => setRefundReason(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-black"
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsRefundModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button
              onClick={handleRefund}
              className="bg-red-500 text-white py-2 px-4 rounded"
            >
              Confirm Refund
            </button>
          </div>
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
};

export default PaymentsManagement;
