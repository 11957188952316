import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import StripeCheckout from '../components/StripeCheckout';
import PayPalCheckout from '../components/PayPalCheckout';
import WebPaymentForm from '../components/WebPaymentForm';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCart } from '../../src/components/contexts/CartContext';
import { useUser } from '../components/contexts/UserContext';
import { Web3Context } from '../components/contexts/Web3Context'; // Import Web3 context
import ClipLoader from 'react-spinners/ClipLoader';
import { motion } from 'framer-motion';
import { FaTrash, FaMinus, FaPlus } from 'react-icons/fa';
import { GiShoppingBag } from 'react-icons/gi';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BsLightningChargeFill } from 'react-icons/bs'; // Web3 Icon

const ViewCart = () => {
  const { cart, fetchCart, removeItemFromCart, addItemToCart, clearCart, cryptoRate } = useCart();
  const { user } = useUser();
  const { account } = useContext(Web3Context); // Web3 account
  const [cartDetails, setCartDetails] = useState([]);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWeb3Form, setShowWeb3Form] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const details = await Promise.all(
        cart.map(async (item) => {
          try {
            if (item.productId) {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${item.productId}`, {
                headers: { Authorization: `Bearer ${token}` }
              });
              return { ...item, product: response.data };
            }
          } catch (error) {
            console.error('Failed to fetch item details:', error);
            return { ...item, error: 'Failed to fetch details' };
          }
          return item;
        })
      );
      setCartDetails(details);
      setLoading(false);
    };

    if (cart.length > 0) {
      fetchDetails();
    } else {
      setCartDetails([]);
      setLoading(false);
    }
  }, [cart, token]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/recommendations`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setRecommendedProducts(response.data);
      } catch (error) {
        console.error('Failed to fetch recommendations:', error);
      }
    };

    fetchRecommendations();
  }, [token]);

  const handleRemoveItem = async (item) => {
    try {
      await removeItemFromCart(item.id);
      toast.success('Item removed from cart!');
    } catch (error) {
      console.error('Failed to remove item from cart:', error);
      toast.error('Failed to remove item from cart.');
    }
  };

  const handleIncreaseQuantity = async (item) => {
    try {
      await addItemToCart({
        productId: item.productId,
        productStockId: item.productStockId,
        quantity: 1,
      });
      toast.success('Item quantity increased!');
    } catch (error) {
      console.error('Failed to increase quantity:', error);
      toast.error('Failed to increase quantity.');
    }
  };

  const handleDecreaseQuantity = async (item) => {
    if (item.quantity > 1) {
      try {
        await addItemToCart({
          productId: item.productId,
          productStockId: item.productStockId,
          quantity: -1,
        });
        toast.success('Item quantity decreased!');
      } catch (error) {
        console.error('Failed to decrease quantity:', error);
        toast.error('Failed to decrease quantity.');
      }
    } else {
      handleRemoveItem(item);
    }
  };

  const totalPrice = cartDetails.reduce((sum, item) => {
    const price = item.product?.price || 0;
    return sum + parseFloat(price) * item.quantity;
  }, 0).toFixed(2);

  const getConvertedPrice = (price) => {
    if (cryptoRate) {
      return (price / cryptoRate).toFixed(4); // Convert GBP to ETH
    }
    return null;
  };

  const handlePaymentSuccess = () => {
    toast.success('Payment successful!');
    clearCart();
    navigate('/bookings');
  };

  const handleWeb3Payment = () => {
    setShowWeb3Form(true); // Show the Web3.0 payment form
  };

  const getImageUrl = (item) => {
    const imageUrl = item.product?.imageUrl;
    if (!imageUrl) {
      return '/default-image.jpg';
    }
    return imageUrl.startsWith('uploads/') ? `${process.env.REACT_APP_API_URL}/${imageUrl}` : `${process.env.REACT_APP_API_URL}/uploads/${imageUrl}`;
  };

  if (account) {
    // Enhanced Web3 Cart Interface
    return (
      <div className="container mx-auto px-4 py-8 bg-gradient-to-br from-blue-900 via-black to-gray-900 text-white min-h-screen shadow-2xl rounded-3xl backdrop-filter backdrop-blur-lg bg-opacity-70">
        <ToastContainer />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8"
        >
          <AiFillThunderbolt className="text-7xl mx-auto text-yellow-400 drop-shadow-lg" />
          <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-300 mt-4">Web3 Cart</h1>
        </motion.div>

        {loading ? (
          <div className="flex justify-center items-center">
            <ClipLoader color={"#fff"} loading={loading} size={150} />
          </div>
        ) : cartDetails.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-900 shadow-2xl rounded-xl overflow-hidden">
              <thead className="bg-gradient-to-r from-yellow-600 to-yellow-400 text-gray-800">
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Item</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Price</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Size</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Color</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Quantity</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Total</th>
                  <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-gray-800 divide-y divide-gray-700">
                {cartDetails.map((item) => (
                  <motion.tr
                    key={item.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="hover:bg-gray-700 hover:shadow-lg transition-all"
                  >
                    <td className="py-4 px-6">
                      <Link to={`/product/${item.product?.id}`} className="flex items-center">
                        <motion.img
                          src={getImageUrl(item)}
                          alt={item.product?.name}
                          className="w-20 h-20 object-cover rounded mr-4 shadow-lg hover:shadow-xl transition-transform"
                          whileHover={{ scale: 1.1 }}
                        />
                        <span className="font-medium text-gray-200">{item.product?.name}</span>
                      </Link>
                    </td>
                    <td className="py-4 px-6 text-gray-300">
                      £{item.product?.price}
                      {cryptoRate && (<span> ({getConvertedPrice(item.product?.price)} ETH)</span>)}
                    </td>
                    <td className="py-4 px-6 text-gray-300">{item.productStock?.size}</td>
                    <td className="py-4 px-6 text-gray-300">{item.productStock?.color}</td>
                    <td className="py-4 px-6 text-gray-300">
                      <div className="flex items-center space-x-2">
                        <motion.button
                          onClick={() => handleDecreaseQuantity(item)}
                          className="px-2 py-1 text-gray-900 bg-gray-300 hover:bg-gray-400 rounded-full shadow-md"
                          whileHover={{ scale: 1.2 }}
                          aria-label="Decrease quantity"
                        >
                          <FaMinus />
                        </motion.button>
                        <span className="text-lg font-bold">{item.quantity}</span>
                        <motion.button
                          onClick={() => handleIncreaseQuantity(item)}
                          className="px-2 py-1 text-gray-900 bg-gray-300 hover:bg-gray-400 rounded-full shadow-md"
                          whileHover={{ scale: 1.2 }}
                          aria-label="Increase quantity"
                        >
                          <FaPlus />
                        </motion.button>
                      </div>
                    </td>
                    <td className="py-4 px-6 text-gray-300">
                      £{(item.product?.price * item.quantity).toFixed(2)}
                      {cryptoRate && (<span> ({getConvertedPrice(item.product?.price * item.quantity)} ETH)</span>)}
                    </td>
                    <td className="py-4 px-6">
                      <motion.button
                        onClick={() => handleRemoveItem(item)}
                        className="px-4 py-2 text-red-600 bg-red-100 hover:bg-red-200 rounded-full shadow-md"
                        whileHover={{ scale: 1.1 }}
                        aria-label="Remove item"
                      >
                        <FaTrash />
                      </motion.button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>

            <motion.div
              className="mt-8 text-right"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold">
                Total: £{totalPrice}
                {cryptoRate && (<span> ({getConvertedPrice(totalPrice)} ETH)</span>)}
              </h2>

              <div className="flex justify-end space-x-4 mt-4">
                <motion.button
                  onClick={handleWeb3Payment}
                  className="relative bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition-all hover:scale-105 focus:outline-none focus:ring-4 focus:ring-indigo-400"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  aria-label="Pay with Web3"
                >
                  {showWeb3Form ? (
                    <span className="flex justify-center items-center">
                      <ClipLoader color={"#fff"} loading={showWeb3Form} size={25} />
                      Processing...
                    </span>
                  ) : (
                    <>
                      <BsLightningChargeFill className="inline-block mr-2 text-lg" />
                      Pay with Web3
                    </>
                  )}
                  {/* Ripple Effect */}
                  <span className="absolute inset-0 rounded-full bg-gradient-to-r from-indigo-400 to-purple-500 opacity-0 hover:opacity-30 transition-opacity duration-300"></span>
                </motion.button>
              </div>
            </motion.div>

            <motion.div
              className="mt-12 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold mb-4 text-green-400">Recommended for You</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {recommendedProducts.map((product) => (
                  <motion.div
                    key={product.id}
                    className="bg-gray-700 rounded-lg p-6 shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
                    whileHover={{ scale: 1.05 }}
                  >
                    <Link to={`/product/${product.id}`} className="block">
                      <img src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`} alt={product.name} className="w-full h-48 object-cover rounded-lg mb-4" />
                      <h3 className="text-xl font-semibold text-gray-200">{product.name}</h3>
                      <p className="text-lg font-bold text-gray-300">£{product.price}</p>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        ) : (
          <motion.div
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <GiShoppingBag className="text-6xl mx-auto text-gray-500 mb-4" />
            <p className="text-lg text-gray-300">Your cart is empty.</p>
            <Link to="/store" className="text-blue-500 hover:text-blue-700 mt-4 inline-block">
              Continue shopping
            </Link>
          </motion.div>
        )}

        {showWeb3Form && (
          <WebPaymentForm
            total={totalPrice}
            onSuccess={handlePaymentSuccess}
            cryptoRate={cryptoRate}
            onClose={() => setShowWeb3Form(false)}
          />
        )}
      </div>
    );
  }

  // Regular Cart Interface for Non-Web3 Users
  return (
    <div className="container mx-auto px-4 py-8 bg-white text-black min-h-screen shadow-lg rounded-lg">
      <ToastContainer />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-8"
      >
        <GiShoppingBag className="text-6xl mx-auto text-blue-500" />
        <h1 className="text-5xl font-extrabold mt-4">Your Cart</h1>
      </motion.div>

      {loading ? (
        <div className="flex justify-center items-center">
          <ClipLoader color={"#000"} loading={loading} size={150} />
        </div>
      ) : cartDetails.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-100 shadow-lg rounded-lg">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Item</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Price</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Size</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Color</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Quantity</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Total</th>
                <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {cartDetails.map((item) => (
                <motion.tr
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="hover:bg-gray-50"
                >
                  <td className="py-4 px-6">
                    <Link to={`/product/${item.product?.id}`} className="flex items-center">
                      <img src={getImageUrl(item)} alt={item.product?.name} className="w-20 h-20 object-cover rounded mr-4 shadow-sm" />
                      <span className="font-medium text-gray-700">{item.product?.name}</span>
                    </Link>
                  </td>
                  <td className="py-4 px-6 text-gray-900">£{item.product?.price}</td>
                  <td className="py-4 px-6 text-gray-900">{item.productStock?.size}</td>
                  <td className="py-4 px-6 text-gray-900">{item.productStock?.color}</td>
                  <td className="py-4 px-6 text-gray-900">
                    <div className="flex items-center">
                      <button
                        onClick={() => handleDecreaseQuantity(item)}
                        className="px-2 py-1 text-gray-900 bg-gray-300 hover:bg-gray-400 rounded shadow-sm"
                        aria-label="Decrease quantity"
                      >
                        <FaMinus />
                      </button>
                      <span className="mx-2">{item.quantity}</span>
                      <button
                        onClick={() => handleIncreaseQuantity(item)}
                        className="px-2 py-1 text-gray-900 bg-gray-300 hover:bg-gray-400 rounded shadow-sm"
                        aria-label="Increase quantity"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </td>
                  <td className="py-4 px-6 text-gray-900">£{(item.product?.price * item.quantity).toFixed(2)}</td>
                  <td className="py-4 px-6">
                    <button
                      onClick={() => handleRemoveItem(item)}
                      className="px-4 py-2 text-red-500 bg-red-100 hover:bg-red-200 rounded shadow-sm"
                      aria-label="Remove item"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>

          <motion.div
            className="mt-8 text-right"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold">Total: £{totalPrice}</h2>

            <div className="flex justify-end space-x-4 mt-4">
              <StripeCheckout total={totalPrice} onPaymentSuccess={handlePaymentSuccess} />
              <PayPalCheckout total={totalPrice} />
            </div>
          </motion.div>

          <motion.div
            className="mt-8 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-4 text-blue-500">Recommended for You</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {recommendedProducts.map((product) => (
                <motion.div
                  key={product.id}
                  className="bg-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                  whileHover={{ scale: 1.05 }}
                >
                  <Link to={`/product/${product.id}`} className="block">
                    <img src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`} alt={product.name} className="w-full h-48 object-cover rounded mb-4" />
                    <h3 className="text-xl font-semibold text-gray-700">{product.name}</h3>
                    <p className="text-lg font-bold text-gray-900">£{product.price}</p>
                  </Link>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      ) : (
        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <GiShoppingBag className="text-6xl mx-auto text-gray-500 mb-4" />
          <p className="text-lg text-gray-700">Your cart is empty.</p>
          <Link to="/store" className="text-blue-500 hover:text-blue-700 mt-4 inline-block">
            Continue shopping
          </Link>
        </motion.div>
      )}
    </div>
  );
};

export default ViewCart;
