import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from './contexts/UserContext';

const AddProductForm = ({ onClose, onProductAdded }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [stockOptions, setStockOptions] = useState([{ size: '', color: '', stock: 0 }]);
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState('');
  const { token } = useUser();

  const handleAddStockOption = () => {
    setStockOptions([...stockOptions, { size: '', color: '', stock: 0 }]);
  };

  const handleRemoveStockOption = (index) => {
    setStockOptions(stockOptions.filter((_, i) => i !== index));
  };

  const handleStockOptionChange = (index, key, value) => {
    const newStockOptions = [...stockOptions];
    newStockOptions[index][key] = value;
    setStockOptions(newStockOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('image', image);
    formData.append('category', category);
    formData.append('stockOptions', JSON.stringify(stockOptions));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      onProductAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Failed to add product:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-8 rounded-lg shadow-lg w-1/2">
        <h2 className="text-2xl font-bold mb-4 text-white">Add New Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border rounded w-full py-2 px-3 bg-gray-700 text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border rounded w-full py-2 px-3 bg-gray-700 text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="border rounded w-full py-2 px-3 bg-gray-700 text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              className="border rounded w-full py-2 px-3 bg-gray-700 text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Category</label>
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="border rounded w-full py-2 px-3 bg-gray-700 text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Stock Options</label>
            {stockOptions.map((option, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={option.size}
                  onChange={(e) => handleStockOptionChange(index, 'size', e.target.value)}
                  placeholder="Size"
                  className="border rounded w-1/3 py-2 px-3 mr-2 bg-gray-700 text-white"
                />
                <input
                  type="text"
                  value={option.color}
                  onChange={(e) => handleStockOptionChange(index, 'color', e.target.value)}
                  placeholder="Color"
                  className="border rounded w-1/3 py-2 px-3 mr-2 bg-gray-700 text-white"
                />
                <input
                  type="number"
                  value={option.stock}
                  onChange={(e) => handleStockOptionChange(index, 'stock', e.target.value)}
                  placeholder="Stock"
                  className="border rounded w-1/3 py-2 px-3 bg-gray-700 text-white"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveStockOption(index)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddStockOption}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              Add Stock Option
            </button>
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2">
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Add Product
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductForm;
