// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isMobile, isTablet } from 'react-device-detect';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './Pages/Homepage';
import Store from './Pages/Store';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import Footer from './components/Footer';
import './i18n';
import NotFound from './components/NotFound';
import ContactUs from './components/ContactUs';
import ViewCart from './Pages/ViewCart';
import Orders from './components/Orders';
import ProfilePage from './components/Profile';
import ResetPassword from './components/ResetPassword';
import { StoreProvider } from './components/contexts/StoreContext';
import SharedWishlist from './components/SharedWishlist';
import ProductPage from './components/ProductPage';
import TermsAndConditions from './components/TermsAndConditions';
// Importing AdminPage
import AdminPage from './Pages/Admin'; // Import the Admin page
import { useUser } from './components/contexts/UserContext';

const stripePromise = loadStripe('your-publishable-key-here');

const App = () => {
  const [notifications, setNotifications] = useState([]);
  const { initializeWeb3, isWeb3User, user } = useUser();

  const addNotification = (message) => {
    setNotifications((prevNotifications) => [...prevNotifications, message]);
  };

  const removeNotification = (index) => {
    setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
  };

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    if (!isWeb3User) {
      initializeWeb3();
    }
  }, [initializeWeb3, isWeb3User]);

  return (
    <div>
      <StoreProvider>
        {!isHomePage && (isMobile || isTablet ? (
          <MobileNavbar notifications={notifications} addNotification={addNotification} removeNotification={removeNotification} />
        ) : (
          <Navbar notifications={notifications} addNotification={addNotification} removeNotification={removeNotification} />
        ))}
        <Routes>
          <Route path="/" element={<HomePage addNotification={addNotification} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/store" element={<Store />} />
          <Route path="/ViewCart" element={
            <Elements stripe={stripePromise}>
              <ViewCart />
            </Elements>
          } />
          <Route path="/orders" element={<Orders />} />
          <Route path="/profile" element={
            <Elements stripe={stripePromise}>
              <ProfilePage />
            </Elements>
          } />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/wishlist/:token" element={<SharedWishlist />} />
          <Route path="/product/:id" element={<ProductPage />} />
          {/* Admin route - only accessible to admins */}
          <Route path="/admin" element={user && user.role === 'admin' ? <AdminPage /> : <Navigate to="/" />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {!isHomePage && <Footer />}
        {/* Comment out the MetaMask Login if not needed */}
        {/* <LoginWithMetaMask /> */}
      </StoreProvider>
    </div>
  );
};

export default App;
