import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { motion } from 'framer-motion';
import { AiOutlineLoading3Quarters, AiFillInfoCircle } from 'react-icons/ai';
import { FaEthereum } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import { useUser } from './contexts/UserContext'; // Import useUser context

const WebPaymentForm = ({ total, onSuccess, cryptoRate, onClose }) => {
  const { user } = useUser(); // Use the user context to get the current user
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [shippingMethod, setShippingMethod] = useState('standard');
  const [loading, setLoading] = useState(false);
  const [isCryptoValid, setIsCryptoValid] = useState(false);
  const [error, setError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const shippingCost = shippingMethod === 'express' ? 10 : 5;
  const totalWithShipping = (parseFloat(total) + shippingCost).toFixed(2);
  const cryptoTotal = (totalWithShipping / cryptoRate).toFixed(4);

  useEffect(() => {
    if (user) {
      // Populate form fields with user data
      setFullName(`${user.firstName} ${user.lastName}`);
      setEmail(user.email);
      setAddress(user.shippingAddress || '');
      setCity(user.shippingCity || '');
      setState(user.shippingCounty || '');
      setZip(user.shippingPostcode || '');
      setCountry(user.shippingCountry || '');
    }
  }, [user]);

  useEffect(() => {
    if (cryptoTotal && isFinite(cryptoTotal) && cryptoTotal > 0 && cryptoTotal !== 'Infinity') {
      setIsCryptoValid(true);
    } else {
      setIsCryptoValid(false);
      setError('Invalid cryptocurrency amount.');
    }
  }, [cryptoTotal]);

  useEffect(() => {
    if (fullName && email && address && city && state && zip && country && isCryptoValid) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [fullName, email, address, city, state, zip, country, isCryptoValid]);

  const handlePayment = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!cryptoTotal || !isFinite(cryptoTotal) || cryptoTotal === 'Infinity') {
        throw new Error('Invalid cryptocurrency amount.');
      }

      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        const accounts = await web3.eth.getAccounts();
        const amountInWei = web3.utils.toWei(cryptoTotal.toString(), 'ether');

        const balance = await web3.eth.getBalance(accounts[0]);
        const estimatedGas = await web3.eth.estimateGas({
          from: accounts[0],
          to: process.env.REACT_APP_RECEIVER_WALLET_ADDRESS,
          value: amountInWei,
        });
        const gasPrice = await web3.eth.getGasPrice();
        const totalCost = parseInt(amountInWei) + estimatedGas * gasPrice;

        if (parseInt(balance) < totalCost) {
          throw new Error('Insufficient funds to cover the transaction and gas fees.');
        }

        await web3.eth.sendTransaction({
          from: accounts[0],
          to: process.env.REACT_APP_RECEIVER_WALLET_ADDRESS,
          value: amountInWei,
          gas: estimatedGas,
        });

        setLoading(false);
        onSuccess();
      } else {
        throw new Error('MetaMask is not installed.');
      }
    } catch (error) {
      console.error('Payment failed:', error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-gray-900 p-8 rounded-2xl w-full max-w-xl shadow-2xl relative"
        style={{ minWidth: '500px' }} 
      >
        <button
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-200 text-3xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="flex justify-center mb-4">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <FaEthereum className="text-5xl text-blue-500" />
          </motion.div>
        </div>
        <h2 className="text-4xl font-bold text-white mb-6 text-center">Web3 Payment</h2>
        <div className="space-y-6">
          <div>
            <label className="block text-gray-400 mb-1">Full Name <AiFillInfoCircle className="inline-block text-blue-400" data-tooltip-id="name-tooltip" /></label>
            <Tooltip id="name-tooltip" effect="solid">Please enter your full name.</Tooltip>
            <input
              type="text"
              className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter your full name"
            />
          </div>
          <div>
            <label className="block text-gray-400 mb-1">Email Address <AiFillInfoCircle className="inline-block text-blue-400" data-tooltip-id="email-tooltip" /></label>
            <Tooltip id="email-tooltip" effect="solid">We will send your receipt to this email.</Tooltip>
            <input
              type="email"
              className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
            />
          </div>
          <div>
            <label className="block text-gray-400 mb-1">Shipping Address <AiFillInfoCircle className="inline-block text-blue-400" data-tooltip-id="address-tooltip" /></label>
            <Tooltip id="address-tooltip" effect="solid">Where should we ship your items?</Tooltip>
            <input
              type="text"
              className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Street Address"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-400 mb-1">City</label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
              />
            </div>
            <div>
              <label className="block text-gray-400 mb-1">State/Province</label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State/Province"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-400 mb-1">ZIP/Postal Code</label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                placeholder="ZIP/Postal Code"
              />
            </div>
            <div>
              <label className="block text-gray-400 mb-1">Country</label>
              <input
                type="text"
                className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Country"
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-400 mb-1">Shipping Method <AiFillInfoCircle className="inline-block text-blue-400" data-tooltip-id="shipping-tooltip" /></label>
            <Tooltip id="shipping-tooltip" effect="solid">Choose your preferred shipping method.</Tooltip>
            <select
              className="w-full p-3 rounded-lg bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={shippingMethod}
              onChange={(e) => setShippingMethod(e.target.value)}
            >
              <option value="standard">Standard Shipping - £5 ({(5 / cryptoRate).toFixed(4)} ETH)</option>
              <option value="express">Express Shipping - £10 ({(10 / cryptoRate).toFixed(4)} ETH)</option>
            </select>
          </div>
          <div className="text-gray-200 font-bold text-lg text-center">
            Total: £{totalWithShipping} ({cryptoTotal} ETH)
          </div>
        </div>
        <motion.button
          className={`w-full mt-6 ${isFormValid ? 'bg-green-600 hover:bg-green-800' : 'bg-gray-600 cursor-not-allowed'} text-white font-bold py-3 px-4 rounded-full shadow-lg transform transition-all hover:scale-105`}
          onClick={handlePayment}
          disabled={loading || !isCryptoValid || !isFormValid}
          whileHover={isFormValid ? { scale: 1.1 } : {}}
        >
          {loading ? (
            <span className="flex justify-center items-center">
              <AiOutlineLoading3Quarters className="animate-spin mr-2" />
              Processing...
            </span>
          ) : (
            <span className="flex justify-center items-center">
              <FaEthereum className="mr-2" /> Pay with Web3
            </span>
          )}
        </motion.button>
        {error && (
          <motion.div
            className="text-red-500 text-center mt-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {error}
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default WebPaymentForm;
