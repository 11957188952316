// src/adjustForDevice.js
document.addEventListener("DOMContentLoaded", function () {
    function adjustForDevice() {
      const width = window.innerWidth;
  
      if (width <= 600) {
        // Mobile
        document.body.classList.add("mobile");
        document.body.classList.remove("tablet", "desktop");
      } else if (width > 600 && width <= 768) {
        // Tablet
        document.body.classList.add("tablet");
        document.body.classList.remove("mobile", "desktop");
      } else {
        // Desktop
        document.body.classList.add("desktop");
        document.body.classList.remove("mobile", "tablet");
      }
    }
  
    adjustForDevice();
    window.addEventListener("resize", adjustForDevice);
  });
  