// src/components/OrderStats.js
import React from 'react';

const OrderStats = ({ totalOrders, totalPending, totalProcessed, totalShipped, totalDelivered }) => {
  return (
    <div className="flex justify-around my-4">
      <div className="bg-blue-500 text-white p-4 rounded shadow">
        <h3 className="font-bold text-xl">Total Orders</h3>
        <p>{totalOrders}</p>
      </div>
      <div className="bg-yellow-500 text-white p-4 rounded shadow">
        <h3 className="font-bold text-xl">Pending</h3>
        <p>{totalPending}</p>
      </div>
      <div className="bg-green-500 text-white p-4 rounded shadow">
        <h3 className="font-bold text-xl">Processed</h3>
        <p>{totalProcessed}</p>
      </div>
      <div className="bg-purple-500 text-white p-4 rounded shadow">
        <h3 className="font-bold text-xl">Shipped</h3>
        <p>{totalShipped}</p>
      </div>
      <div className="bg-red-500 text-white p-4 rounded shadow">
        <h3 className="font-bold text-xl">Delivered</h3>
        <p>{totalDelivered}</p>
      </div>
    </div>
  );
};

export default OrderStats;
