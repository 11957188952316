import React, { useState, useEffect } from 'react';
import axios from 'axios';

const InventoryManagement = () => {
  const [inventoryAlerts, setInventoryAlerts] = useState([]);

  useEffect(() => {
    const fetchInventoryAlerts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/inventory-alerts`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setInventoryAlerts(response.data);
      } catch (error) {
        console.error('Failed to fetch inventory alerts:', error);
      }
    };

    fetchInventoryAlerts();
  }, []);

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4">Inventory Alerts</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Product ID</th>
            <th className="py-2 px-4 border-b">Product Name</th>
            <th className="py-2 px-4 border-b">Stock Level</th>
            <th className="py-2 px-4 border-b">User</th>
          </tr>
        </thead>
        <tbody>
          {inventoryAlerts.map((alert) => (
            <tr key={alert.id}>
              <td className="py-2 px-4 border-b">{alert.product.id}</td>
              <td className="py-2 px-4 border-b">{alert.product.name}</td>
              <td className="py-2 px-4 border-b">{alert.stockLevel}</td>
              <td className="py-2 px-4 border-b">{alert.user.username}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryManagement;
