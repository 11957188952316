import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8">Terms & Conditions</h1>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md text-white">
        <p>Since every product is made to order, there are no refunds, exchanges, or returns accepted.</p>
        <br />
        <p>In the unlikely event that you get a damaged or inaccurate item, please inform us so we can address the issue as soon as possible.</p>
        <br />
        <p>Proxima Centauri* disclaims all liability for misplaced or stolen parcels during the delivery process. It is necessary to pursue compensation for such situations directly with the mail service provider.</p>
        <br />
        <p>Furthermore, inputting inaccurate information during the purchase procedure will not hold Proxima Centauri* responsible for any problems.</p>
        <br />
        <p>The timeliness, accuracy, and completeness of the information on this website are not guaranteed by us. The material offered is just meant to be used as a basic guide; it shouldn't serve as the only foundation for making significant decisions. When making important judgements, it is advisable to examine more trustworthy and timely sources.</p>
        <br />
        <p>You bear all liability for relying on any content on this website.</p>
        <br />
        <p>There may be historical material on this website, however it is important to remember that it may not be accurate and is only meant for reference. We are not required to update the material, even though we reserve the right to change any part of this site's content at any moment. You are in charge of keeping an eye on any modifications made to our website and Instagram.</p>
        <br />
        <h2 className="text-2xl font-bold mb-4">DELIVERY</h2>
        <p>Since each item is made by hand, please allow 5–28 working days for shipment (varies worldwide).</p>
        <br />
        <p>We appreciate your patience while we attempt to complete the order in the event that it takes longer than expected. Supplier problems or delays might be the cause of this delay.</p>
        <br />
        <p>UK ORDERS: £3.99 via Royal Mail Signed-For</p>
        <p>INTERNATIONAL ORDERS: £9.00 via Royal Mail International Signed-For</p>
        <br />
        <h2 className="text-2xl font-bold mb-4">WASHING INSTRUCTIONS</h2>
        <p>It is advised to wash all products inside out in cold water on a moderate washing cycle in order to preserve the quality of the product. Steer clear of ironing and tumble drying the print immediately.</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
