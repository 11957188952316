import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { FaBoxOpen, FaUsers, FaMoneyBillWave, FaExclamationTriangle } from 'react-icons/fa';

// Register the components with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Overview = () => {
  const [data, setData] = useState({
    totalSales: 0,
    totalOrders: 0,
    totalUsers: 0,
    inventoryAlerts: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/overview`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setData(response.data);
      } catch (error) {
        console.error('Failed to fetch overview data:', error);
      }
    };

    fetchData();
  }, []);

  const barChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Sales',
        data: [5000, 3000, 4000, 6000, 7000, 8000],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const doughnutChartData = {
    labels: ['Male', 'Female', 'Other'],
    datasets: [
      {
        data: [60, 30, 10],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56'],
      },
    ],
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaMoneyBillWave className="text-3xl mr-4" />
          <div>
            <h2 className="text-xl">Total Sales</h2>
            <p className="text-2xl font-bold">£{data.totalSales}</p>
          </div>
        </div>
        <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaBoxOpen className="text-3xl mr-4" />
          <div>
            <h2 className="text-xl">Total Orders</h2>
            <p className="text-2xl font-bold">{data.totalOrders}</p>
          </div>
        </div>
        <div className="bg-yellow-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaUsers className="text-3xl mr-4" />
          <div>
            <h2 className="text-xl">Total Users</h2>
            <p className="text-2xl font-bold">{data.totalUsers}</p>
          </div>
        </div>
        <div className="bg-red-500 text-white p-6 rounded-lg shadow-lg flex items-center">
          <FaExclamationTriangle className="text-3xl mr-4" />
          <div>
            <h2 className="text-xl">Inventory Alerts</h2>
            <p className="text-2xl font-bold">{data.inventoryAlerts}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Sales Overview</h2>
          <Bar data={barChartData} />
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">User Demographics</h2>
          <Doughnut data={doughnutChartData} />
        </div>
      </div>
    </div>
  );
};

export default Overview;
