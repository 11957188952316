import React, { createContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import CryptoJS from 'crypto-js';

export const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAccount = async () => {
      if (window.ethereum) {
        try {
          setIsLoading(true);
          const provider = new ethers.BrowserProvider(window.ethereum);
          const accounts = await provider.listAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            saveAccountToStorage(accounts[0]);
          }
        } catch (error) {
          console.error('Error fetching accounts:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    // Load the account from localStorage on initial load
    const storedAccount = loadAccountFromStorage();
    if (storedAccount) {
      setAccount(storedAccount);
    }

    loadAccount();

    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0) {
        setAccount(accounts[0]);
        saveAccountToStorage(accounts[0]);
      } else {
        setAccount(null);
        clearAccountFromStorage();
      }
    };

    window.ethereum?.on('accountsChanged', handleAccountsChanged);

    return () => {
      window.ethereum?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  const saveAccountToStorage = (account) => {
    try {
      const encryptedAccount = CryptoJS.AES.encrypt(account, process.env.REACT_APP_SECRET_KEY).toString();
      localStorage.setItem('web3Account', encryptedAccount);
    } catch (error) {
      console.error('Error saving account to storage:', error);
    }
  };

  const loadAccountFromStorage = () => {
    try {
      const encryptedAccount = localStorage.getItem('web3Account');
      if (encryptedAccount) {
        const bytes = CryptoJS.AES.decrypt(encryptedAccount, process.env.REACT_APP_SECRET_KEY);
        const decryptedAccount = bytes.toString(CryptoJS.enc.Utf8);
        if (!decryptedAccount) {
          throw new Error('Decryption resulted in an empty string');
        }
        return decryptedAccount;
      }
    } catch (error) {
      console.error('Decryption failed:', error);
      clearAccountFromStorage();  // Clear corrupted data
    }
    return null;
  };

  const clearAccountFromStorage = () => {
    localStorage.removeItem('web3Account');
  };

  const disconnectWeb3 = async () => {
    setAccount(null);
    clearAccountFromStorage();
  };

  return (
    <Web3Context.Provider value={{ account, disconnectWeb3, isLoading }}>
      {children}
    </Web3Context.Provider>
  );
};
