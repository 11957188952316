import React from 'react';
import { FaChartLine, FaShoppingCart, FaWarehouse, FaStar } from 'react-icons/fa';

const Dashboard = ({ data }) => {
  return (
    <div className="grid grid-cols-4 gap-4 mb-6">
      <div className="bg-blue-500 text-white p-4 rounded shadow-lg">
        <FaShoppingCart size={32} className="mb-2" />
        <h3 className="text-xl font-bold">Total Products</h3>
        <p className="text-2xl">{data.totalProducts}</p>
      </div>
      <div className="bg-green-500 text-white p-4 rounded shadow-lg">
        <FaWarehouse size={32} className="mb-2" />
        <h3 className="text-xl font-bold">Total Stock</h3>
        <p className="text-2xl">{data.totalStock}</p>
      </div>
      <div className="bg-yellow-500 text-white p-4 rounded shadow-lg">
        <FaStar size={32} className="mb-2" />
        <h3 className="text-xl font-bold">Total Reviews</h3>
        <p className="text-2xl">{data.totalReviews}</p>
      </div>
      <div className="bg-red-500 text-white p-4 rounded shadow-lg">
        <FaChartLine size={32} className="mb-2" />
        <h3 className="text-xl font-bold">Sales</h3>
        <p className="text-2xl">{data.totalSales}</p>
      </div>
    </div>
  );
};

export default Dashboard;
