import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { FaInfoCircle } from 'react-icons/fa';

Modal.setAppElement('#root');

const AIPricingRecommendations = ({ productId, onClose }) => {
    const [recommendedPrice, setRecommendedPrice] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchRecommendations = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ai/recommendations/${productId}`);
                
                // Populate the state with the response data
                setRecommendedPrice(response.data.recommendedPrice);
                setRelatedProducts(response.data.relatedProducts);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch AI recommendations:', error);
                setError('Failed to load recommendations.');
                setLoading(false);
            }
        };
        
        fetchRecommendations();
    }, [productId]);

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            contentLabel="AI Pricing Recommendations"
            className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
            <h2 className="text-3xl font-bold mb-4">AI Pricing Recommendation</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    {recommendedPrice ? (
                        <div>
                            <h3 className="text-xl mb-2">Suggested Price: £{recommendedPrice}</h3>
                            <p className="text-sm text-gray-400 mb-4">
                                This price is based on an analysis of real-time market trends, competitor pricing, and customer demand.
                            </p>
                        </div>
                    ) : (
                        <p>No pricing recommendation available.</p>
                    )}
                    <h3 className="text-xl mb-2">Related Products</h3>
                    {relatedProducts.length > 0 ? (
                        <ul className="list-disc pl-5">
                            {relatedProducts.map(product => (
                                <li key={product.id} className="mb-2">
                                    {product.name} - £{product.price}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No related products found.</p>
                    )}
                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={onClose}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default AIPricingRecommendations;
