import React, { useEffect, useState, useCallback } from 'react';
import api from '../api'; // Import custom axios instance
import { useCart } from '../components/contexts/CartContext';
import AddProductForm from '../components/AddProductForm';
import EditProductForm from '../components/EditProductForm'; // Ensure this is the correct path
import { useUser } from '../components/contexts/UserContext';
import { debounce } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  FaPlus,
  FaHeart,
  FaTimes,
  FaEdit,
  FaTrash,
  FaEye,
  FaStar,
  FaFilter,
  FaShoppingCart
} from 'react-icons/fa'; // Ensure FaShoppingCart is imported
import Modal from 'react-modal';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { motion } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';
import { React360Viewer } from 'react-360-product-viewer';
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from 'react-lazyload';
import { useInView } from 'react-intersection-observer';
import WebPaymentButton from '../components/webpayment';

Modal.setAppElement('#root'); // Ensure to bind modal to your appElement

const Store = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOption, setSortOption] = useState('');
  const [filterSize, setFilterSize] = useState('');
  const [filterColor, setFilterColor] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [customText, setCustomText] = useState('');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const { addItemToCart } = useCart();
  const { user, token } = useUser();
  const [giftWrap, setGiftWrap] = useState(false);
  const [giftMessage, setGiftMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showSocialProof, setShowSocialProof] = useState(false);
  const [recentProduct, setRecentProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [userReview, setUserReview] = useState({ rating: 0, comment: '' });
  const [userHasPurchased, setUserHasPurchased] = useState(false);
  const [filterOptions, setFilterOptions] = useState({ sizes: [], colors: [] });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [cryptoRate, setCryptoRate] = useState(null);

  const cache = {};

  const fetchCryptoRate = useCallback(async (retryCount = 0) => {
    try {
      const response = await api.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=gbp');
      setCryptoRate(response.data.ethereum.gbp);
      localStorage.setItem('cryptoRate', JSON.stringify(response.data.ethereum.gbp));
      localStorage.setItem('cryptoRateTime', Date.now());
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < 5) {
        const delay = Math.pow(2, retryCount) * 1000;
        setTimeout(() => fetchCryptoRate(retryCount + 1), delay);
      } else {
        console.error('Failed to fetch crypto rate:', error);
        const cachedRate = localStorage.getItem('cryptoRate');
        if (cachedRate) {
          setCryptoRate(JSON.parse(cachedRate));
        }
      }
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    if (cache['categories']) {
      setCategories(cache['categories']);
      return;
    }
    try {
      const response = await api.get('/products');
      const fetchedCategories = response.data.map(product => product.category);
      const uniqueCategories = [...new Set(fetchedCategories)];
      setCategories(uniqueCategories);
      cache['categories'] = uniqueCategories;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Rate limit exceeded. Please try again later.');
      } else {
        console.error('Failed to fetch categories:', error);
      }
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    const cacheKey = `products_${selectedCategory}_${sortOption}_${filterSize}_${filterColor}_${currentPage}`;
    if (cache[cacheKey]) {
      setProducts(cache[cacheKey].products);
      setTotalPages(cache[cacheKey].totalPages);
      setIsLoading(false);
      return;
    }
    try {
      const response = await api.get('/products', {
        params: {
          category: selectedCategory,
          sort: sortOption,
          size: filterSize,
          color: filterColor,
          page: currentPage,
        },
      });
      console.log('Fetched products:', response.data);
      if (Array.isArray(response.data)) {
        setProducts(response.data);
        setTotalPages(1);
        cache[cacheKey] = {
          products: response.data,
          totalPages: 1,
        };
      } else {
        console.error('Unexpected response structure:', response.data);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Rate limit exceeded. Please try again later.');
      } else {
        console.error('Failed to fetch products:', error);
      }
      setIsLoading(false);
    }
  }, [selectedCategory, sortOption, filterSize, filterColor, currentPage]);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await api.get('/products/filters');
      setFilterOptions(response.data);
    } catch (error) {
      console.error('Failed to fetch filter options:', error);
    }
  }, []);

  const fetchRelatedProducts = useCallback(async (productId) => {
    try {
      const response = await api.get(`/products/related/${productId}`);
      setRelatedProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch related products:', error);
    }
  }, []);

  const fetchProductReviews = useCallback(async (productId) => {
    try {
      const response = await api.get(`/products/${productId}/reviews`);
      setReviews(response.data);
    } catch (error) {
      console.error('Failed to fetch reviews:', error);
    }
  }, []);

  const fetchUserOrders = useCallback(async () => {
    try {
      const response = await api.get(`/api/orders`);
      console.log('fetchUserOrders response:', response);
      return response.data.orders;
    } catch (error) {
      console.error('Failed to fetch user orders:', error);
      return [];
    }
  }, []);

  const debouncedFetchCategories = useCallback(debounce(fetchCategories, 1000), [fetchCategories]);
  const debouncedFetchProducts = useCallback(debounce(fetchProducts, 1000), [fetchProducts]);

  useEffect(() => {
    debouncedFetchCategories();
    fetchFilterOptions();
    const cacheTime = localStorage.getItem('cryptoRateTime');
    if (cacheTime && Date.now() - cacheTime < 300000) {
      const cachedRate = localStorage.getItem('cryptoRate');
      if (cachedRate) {
        setCryptoRate(JSON.parse(cachedRate));
      }
    } else {
      fetchCryptoRate();
    }
  }, [debouncedFetchCategories, fetchFilterOptions, fetchCryptoRate]);

  useEffect(() => {
    debouncedFetchProducts();
  }, [debouncedFetchProducts]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setCurrentPage(1);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterSizeChange = (event) => {
    setFilterSize(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterColorChange = (event) => {
    setFilterColor(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProductAdded = (newProduct) => {
    setProducts([newProduct, ...products]);
    setIsAddProductModalOpen(false);
  };

  const handleProductEdited = (editedProduct) => {
    setProducts(products.map(p => p.id === editedProduct.id ? editedProduct : p));
    setIsEditProductModalOpen(false);
  };

  const handleAddToCart = (product, size) => {
    if (!size) {
      toast.error('Please select a size.');
      return;
    }

    const selectedStock = product.stockOptions.find(option => option.size === size && option.color === selectedColor);

    if (!selectedStock) {
      toast.error('Selected size and color combination is not available.');
      return;
    }

    const item = { productId: product.id, productStockId: selectedStock.id, quantity: 1 };
    addItemToCart(item);
    toast.success(`${product.name} (Size: ${size}, Color: ${selectedColor}) added to cart!`, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleProductClick = async (product) => {
    setSelectedProduct(product);
    fetchRelatedProducts(product.id);
    fetchProductReviews(product.id);

    if (user) {
      try {
        const userOrders = await fetchUserOrders();
        console.log('User Orders:', userOrders);

        if (userOrders && Array.isArray(userOrders)) {
          const hasPurchased = userOrders.some(order => order.productId === product.id);
          setUserHasPurchased(hasPurchased);
        } else {
          console.error('Unexpected structure for userOrders:', userOrders);
        }
      } catch (error) {
        console.error('Failed to fetch user orders:', error);
      }
    }

    setIsProductDetailsModalOpen(true);
  };

  const handleWishlist = async (product) => {
    try {
      await api.post('/wishlist', { productId: product.id }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.info(`${product.name} added to wishlist!`);
    } catch (error) {
      console.error('Failed to add to wishlist:', error);
      toast.error('Failed to add to wishlist.');
    }
  };

  const handleSaveForLater = (product) => {
    toast.info(`${product.name} saved for later!`);
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setIsEditProductModalOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(products.filter(p => p.id !== productId));
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error('Failed to delete product:', error);
      toast.error('Failed to delete product.');
    }
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setUserReview(prevReview => ({ ...prevReview, [name]: value }));
  };

  const handleSubmitReview = async () => {
    const { rating, comment } = userReview;
    try {
      await api.post(`/products/${selectedProduct.id}/reviews`, { rating, comment }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchProductReviews(selectedProduct.id);
      setUserReview({ rating: 0, comment: '' });
      toast.success('Review added successfully!');
    } catch (error) {
      console.error('Failed to submit review:', error);
      toast.error('Failed to submit review.');
    }
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && products.length > 0) {
      const randomProduct = products[Math.floor(Math.random() * products.length)];
      setRecentProduct(randomProduct);
      setShowSocialProof(true);
      const timer = setTimeout(() => {
        setShowSocialProof(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [inView, products]);

  const getConvertedPrice = (price) => {
    if (user?.web3Address && cryptoRate) {
      return (price / cryptoRate).toFixed(4); // Convert GBP to ETH
    }
    return null;
  };

  return (
    <div className="container mx-auto px-4 py-8 bg-black text-white">
      <ToastContainer />
      <h1 className="text-3xl font-bold mb-8"> </h1>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <ClipLoader color={"#123abc"} loading={isLoading} size={150} />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-between md:space-x-4 mb-4">
            <div className="flex flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0">
              {false && (
                <>
                  <div className="flex items-center">
                    <label className="mr-2">Category:</label>
                    <select value={selectedCategory} onChange={handleCategoryChange} className="border p-2 rounded bg-gray-800 text-white">
                      <option value="">All</option>
                      {categories.length > 0 && categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex items-center">
                    <label className="mr-2">Sort by:</label>
                    <select value={sortOption} onChange={handleSortChange} className="border p-2 rounded bg-gray-800 text-white">
                      <option value="">None</option>
                      <option value="price">Price</option>
                      <option value="name">Name</option>
                    </select>
                  </div>

                  <div className="flex items-center">
                    <label className="mr-2">Filter by Size:</label>
                    <select value={filterSize} onChange={handleFilterSizeChange} className="border p-2 rounded bg-gray-800 text-white">
                      <option value="">All</option>
                      {filterOptions.sizes.map((size, index) => (
                        <option key={index} value={size}>
                          {size}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex items-center">
                    <label className="mr-2">Filter by Color:</label>
                    <select value={filterColor} onChange={handleFilterColorChange} className="border p-2 rounded bg-gray-800 text-white">
                      <option value="">All</option>
                      {filterOptions.colors.map((color, index) => (
                        <option key={index} value={color}>
                          {color}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>

            {user && user.role === 'admin' && (
              <button
                onClick={() => setIsAddProductModalOpen(true)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                <FaPlus className="mr-2" /> Add Product
              </button>
            )}

            {false && (
              <button
                onClick={() => setIsFilterModalOpen(true)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center md:hidden"
              >
                <FaFilter className="mr-2" /> Filters
              </button>
            )}
          </div>

          {false && (
            <Modal
              isOpen={isFilterModalOpen}
              onRequestClose={() => setIsFilterModalOpen(false)}
              contentLabel="Filters"
              className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
            >
              <div className="flex justify-end">
                <button onClick={() => setIsFilterModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>
              <h2 className="text-3xl font-bold mb-4">Filters</h2>
              <div className="flex flex-col space-y-4">
                <div className="flex items-center">
                  <label className="mr-2">Category:</label>
                  <select value={selectedCategory} onChange={handleCategoryChange} className="border p-2 rounded bg-gray-800 text-white">
                    <option value="">All</option>
                    {categories.length > 0 && categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex items-center">
                  <label className="mr-2">Sort by:</label>
                  <select value={sortOption} onChange={handleSortChange} className="border p-2 rounded bg-gray-800 text-white">
                    <option value="">None</option>
                    <option value="price">Price</option>
                    <option value="name">Name</option>
                  </select>
                </div>

                <div className="flex items-center">
                  <label className="mr-2">Filter by Size:</label>
                  <select value={filterSize} onChange={handleFilterSizeChange} className="border p-2 rounded bg-gray-800 text-white">
                    <option value="">All</option>
                    {filterOptions.sizes.map((size, index) => (
                      <option key={index} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex items-center">
                  <label className="mr-2">Filter by Color:</label>
                  <select value={filterColor} onChange={handleFilterColorChange} className="border p-2 rounded bg-gray-800 text-white">
                    <option value="">All</option>
                    {filterOptions.colors.map((color, index) => (
                      <option key={index} value={color}>
                        {color}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  onClick={() => setIsFilterModalOpen(false)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Apply Filters
                </button>
              </div>
            </Modal>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            {products.length > 0 ? (
              products.map((product) => (
                <div
                  key={product.id}
                  className="p-4 shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl cursor-pointer bg-black"
                  onClick={() => handleProductClick(product)}
                >
                  <div className="w-full h-60 flex items-center justify-center overflow-hidden">
                    <Zoom>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
                        alt={product.name}
                        className="object-contain h-full w-full"
                      />
                    </Zoom>
                  </div>
                  <h2 className="text-xl font-bold mb-2">{product.name}</h2>
                  <p className="text-lg font-semibold mb-4">
                    {user?.web3Address && cryptoRate
                      ? `${getConvertedPrice(product.price)} ETH`
                      : `£${product.price}`}
                  </p>
                  {user && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleWishlist(product);
                      }}
                      className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaHeart className="mr-2" /> Wishlist
                    </button>
                  )}
                  {user && user.role === 'admin' && (
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditProduct(product);
                        }}
                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center"
                      >
                        <FaEdit className="mr-2" /> Edit
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProduct(product.id);
                        }}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
                      >
                        <FaTrash className="mr-2" /> Delete
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>

          <div className="flex justify-center mt-8">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 px-3 py-1 border rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}

      {isProductDetailsModalOpen && selectedProduct && (
        <Modal
          isOpen={isProductDetailsModalOpen}
          onRequestClose={() => setIsProductDetailsModalOpen(false)}
          contentLabel="Product Details"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full md:w-3/4 lg:w-1/2 mx-auto my-8 z-50 text-white overflow-y-auto max-h-screen"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <div className="flex justify-end">
            <button onClick={() => setIsProductDetailsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
              <FaTimes className="w-6 h-6" />
            </button>
          </div>
          <h2 className="text-3xl font-bold mb-4">{selectedProduct.name}</h2>
          <div className="mb-4">
            <Zoom>
              <img
                src={`${process.env.REACT_APP_API_URL}/${selectedProduct.imageUrl}`}
                alt={selectedProduct.name}
                className="w-full h-60 object-contain mb-4 rounded-lg"
              />
            </Zoom>
          </div>
          {selectedProduct.image360 && selectedProduct.image360.length > 0 && (
            <div className="mb-4">
              <React360Viewer
                amount={selectedProduct.image360.length}
                imagePath={`${process.env.REACT_APP_API_URL}/${selectedProduct.image360}`}
                fileName="image-{index}.jpg"
                width="100%"
                height="100%"
              />
            </div>
          )}
          <p className="text-gray-400 mb-4">{selectedProduct.description}</p>
          <p className="text-lg font-semibold mb-4">
            {user?.web3Address && cryptoRate
              ? `${getConvertedPrice(selectedProduct.price)} ETH`
              : `£${selectedProduct.price}`}
          </p>
          <div className="mb-4">
            <label className="mr-2">Select Size:</label>
            <div className="flex flex-wrap">
              {selectedProduct.stockOptions.map((option) => (
                <button
                  key={option.size}
                  onClick={() => setSelectedSize(option.size)}
                  disabled={option.stock <= 0}
                  className={`m-1 p-2 rounded border ${selectedSize === option.size ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} ${option.stock <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {option.size}
                  {option.stock > 0 ? ' (In Stock)' : ' (Out of Stock)'}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="mr-2">Select Color:</label>
            <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} className="border p-2 rounded bg-gray-800 text-white">
              <option value="">Select</option>
              {selectedProduct.stockOptions
                .map(option => option.color)
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(color => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="mr-2">Add Text:</label>
            <input
              type="text"
              value={customText}
              onChange={(e) => setCustomText(e.target.value)}
              className="border p-2 rounded bg-gray-800 text-white"
              placeholder="Enter custom text"
            />
          </div>
          <div className="mb-4">
            <label className="mr-2">Gift Wrap:</label>
            <input
              type="checkbox"
              checked={giftWrap}
              onChange={() => setGiftWrap(!giftWrap)}
              className="border p-2 rounded bg-gray-800 text-white"
            />
          </div>
          {giftWrap && (
            <div className="mb-4">
              <label className="mr-2">Gift Message:</label>
              <input
                type="text"
                value={giftMessage}
                onChange={(e) => setGiftMessage(e.target.value)}
                className="border p-2 rounded bg-gray-800 text-white"
                placeholder="Enter gift message"
              />
            </div>
          )}
          <button
            onClick={() => handleAddToCart(selectedProduct, selectedSize)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaShoppingCart className="mr-2" /> Add to Cart
          </button>
          <div className="mt-4">
            <h3 className="text-2xl font-bold mb-2">Related Products</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {relatedProducts.map((product) => (
                <div key={product.id} className="rounded-lg p-4 shadow-lg bg-black">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
                    alt={product.name}
                    className="w-full h-32 object-contain rounded-lg mb-2"
                  />
                  <h4 className="text-xl font-bold">{product.name}</h4>
                  <p className="text-lg font-semibold">
                    {user?.web3Address && cryptoRate
                      ? `${getConvertedPrice(product.price)} ETH`
                      : `£${product.price}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <h3 className="text-2xl font-bold mb-2">Reviews</h3>
            <div className="space-y-4">
              {reviews.map(review => (
                <div key={review.id} className="border rounded-lg p-4 shadow-lg bg-gray-800">
                  <div className="flex items-center mb-2">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} className={i < review.rating ? 'text-yellow-500' : 'text-gray-500'} />
                    ))}
                  </div>
                  <p>{review.comment}</p>
                </div>
              ))}
            </div>
            {userHasPurchased && (
              <div className="mt-4">
                <h4 className="text-xl font-bold mb-2">Add Your Review</h4>
                <div className="mb-2">
                  <label className="mr-2">Rating:</label>
                  <select
                    name="rating"
                    value={userReview.rating}
                    onChange={handleReviewChange}
                    className="border p-2 rounded bg-gray-800 text-white"
                  >
                    <option value={0}>Select rating</option>
                    {[...Array(5)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1} Star{i > 0 && 's'}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2">
                  <label className="mr-2">Comment:</label>
                  <textarea
                    name="comment"
                    value={userReview.comment}
                    onChange={handleReviewChange}
                    className="border p-2 rounded bg-gray-800 text-white w-full"
                    placeholder="Write your review here"
                  />
                </div>
                <button
                  onClick={handleSubmitReview}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit Review
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}

      {isAddProductModalOpen && (
        <AddProductForm
          onClose={() => setIsAddProductModalOpen(false)}
          onProductAdded={handleProductAdded}
        />
      )}

      {isEditProductModalOpen && selectedProduct && (
        <EditProductForm
          product={selectedProduct}
          onClose={() => setIsEditProductModalOpen(false)}
          onProductEdited={handleProductEdited}
        />
      )}

      {showSocialProof && recentProduct && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="fixed bottom-4 left-4 bg-white text-black p-4 rounded-lg shadow-lg z-50"
        >
          <div className="flex items-center">
            <img
              src={`${process.env.REACT_APP_API_URL}/${recentProduct.imageUrl}`}
              alt={recentProduct.name}
              className="w-16 h-16 object-contain rounded-lg mr-4"
            />
            <div>
              <p className="font-bold">{recentProduct.name}</p>
              <p>was recently purchased!</p>
            </div>
            <button
              className="ml-auto text-gray-500 hover:text-gray-700"
              onClick={() => setShowSocialProof(false)}
            >
              <FaTimes className="w-4 h-4" />
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Store;
