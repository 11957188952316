import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useUser } from './UserContext';
import api from '../../api';
import config from './config';
import axios from 'axios';

const CartContext = createContext({
  cart: [],
  fetchCart: () => {},
  addItemToCart: () => {},
  removeItemFromCart: () => {},
  clearCart: () => {},
  cryptoRate: null,
});

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const { user, token } = useUser();
  const [cryptoRate, setCryptoRate] = useState(null);

  const fetchCryptoRate = useCallback(async () => {
    try {
      // Fetch ETH to USD price from Binance
      const response = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT');

      // Convert USD to GBP manually using a fixed rate or another method
      const usdToGbpRate = 0.78; // Use a fixed conversion rate or replace with another API call
      setCryptoRate((response.data.price * usdToGbpRate).toFixed(4));
    } catch (error) {
      console.error('Failed to fetch crypto rate:', error);
    }
  }, []);

  useEffect(() => {
    fetchCryptoRate();
  }, [fetchCryptoRate]);

  const fetchCart = useCallback(async () => {
    if (!token) return;

    try {
      const response = await api.get(`${config.API_URL}/api/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCart(response.data);
    } catch (error) {
      console.error('Failed to fetch cart:', error);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchCart();
    }
  }, [token, fetchCart]);

  const addItemToCart = async (item) => {
    if (!token || !user) {
      const localCart = JSON.parse(localStorage.getItem('cart')) || [];
      const existingItemIndex = localCart.findIndex((i) => i.productStockId === item.productStockId);

      if (existingItemIndex !== -1) {
        localCart[existingItemIndex].quantity += item.quantity;
      } else {
        localCart.push(item);
      }

      localStorage.setItem('cart', JSON.stringify(localCart));
      setCart(localCart);
      return;
    }

    try {
      await api.post(
        `${config.API_URL}/api/cart`,
        {
          userId: user.userId,
          productId: item.productId,
          productStockId: item.productStockId,
          quantity: item.quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchCart();
    } catch (error) {
      console.error('Failed to add item to cart:', error);
    }
  };

  const removeItemFromCart = async (cartId) => {
    if (!token) {
      const localCart = JSON.parse(localStorage.getItem('cart')) || [];
      const updatedCart = localCart.filter((item) => item.cartId !== cartId);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart);
      return;
    }

    try {
      await api.delete(`${config.API_URL}/api/cart/${cartId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await fetchCart();
    } catch (error) {
      console.error('Failed to remove item from cart:', error);
    }
  };

  const clearCart = async () => {
    if (!token) {
      console.error('No token found. Cannot clear cart from database.');
      return;
    }

    try {
      console.log('Attempting to clear cart');
      const response = await api.delete(`${config.API_URL}/api/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 204) {
        setCart([]);
        console.log('Successfully cleared cart from database');
      } else {
        console.error('Failed to clear cart:', response);
      }
    } catch (error) {
      console.error('Failed to clear cart:', error);
    }
  };

  return (
    <CartContext.Provider value={{ cart, fetchCart, addItemToCart, removeItemFromCart, clearCart, cryptoRate }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

export default CartContext;
