import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api'; // Import your custom axios instance
import { useCart } from '../components/contexts/CartContext';
import { useUser } from '../components/contexts/UserContext';
import { FaShoppingCart, FaHeart, FaStar } from 'react-icons/fa';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';

const ProductPage = () => {
  const { id } = useParams();
  const { user, token } = useUser();
  const { addItemToCart } = useCart();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [customText, setCustomText] = useState('');
  const [giftWrap, setGiftWrap] = useState(false);
  const [giftMessage, setGiftMessage] = useState('');
  const [userHasPurchased, setUserHasPurchased] = useState(false);
  const [userReview, setUserReview] = useState({ rating: 0, comment: '' });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}`);
        setProduct(response.data);

        const relatedResponse = await api.get(`/products/related/${id}`);
        setRelatedProducts(relatedResponse.data);

        const reviewsResponse = await api.get(`/products/${id}/reviews`);
        setReviews(reviewsResponse.data);

        if (user) {
          const userOrdersResponse = await api.get(`/api/orders`);
          const purchasedProductIds = userOrdersResponse.data.orders.map(order => order.productId);
          setUserHasPurchased(purchasedProductIds.includes(parseInt(id)));
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
        toast.error('Failed to fetch product details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [id, user]);

  const handleAddToCart = () => {
    if (!selectedSize) {
      toast.error('Please select a size.');
      return;
    }

    const selectedStock = product.stockOptions.find(option => option.size === selectedSize && option.color === selectedColor);

    if (!selectedStock) {
      toast.error('Selected size and color combination is not available.');
      return;
    }

    const item = { productId: product.id, productStockId: selectedStock.id, quantity: 1 };
    addItemToCart(item);
    toast.success(`${product.name} (Size: ${selectedSize}, Color: ${selectedColor}) added to cart!`);
  };

  const handleWishlist = async () => {
    try {
      await api.post('/wishlist', { productId: product.id }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.info(`${product.name} added to wishlist!`);
    } catch (error) {
      console.error('Failed to add to wishlist:', error);
      toast.error('Failed to add to wishlist.');
    }
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userReview.id) {
        await api.put(`/reviews/${userReview.id}`, userReview);
        toast.success('Review updated successfully!');
      } else {
        await api.post(`/reviews`, { ...userReview, productId: id, userId: user.userId });
        toast.success('Review submitted successfully!');
      }
      const reviewsResponse = await api.get(`/products/${id}/reviews`);
      setReviews(reviewsResponse.data);
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('Failed to submit review.');
    }
  };

  const handleStarClick = (rating) => {
    setUserReview({ ...userReview, rating });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <ClipLoader color={"#123abc"} loading={isLoading} size={150} />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      {product ? (
        <div className="border rounded-lg p-4 shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl cursor-pointer bg-gray-800">
          <div className="w-full h-60 flex items-center justify-center overflow-hidden">
            <Zoom>
              <img
                src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
                alt={product.name}
                className="object-contain h-full w-full rounded-lg"
              />
            </Zoom>
          </div>
          <h2 className="text-3xl font-bold mb-2">{product.name}</h2>
          <p className="text-lg font-semibold mb-4">£{product.price}</p>
          <div className="mb-4">
            <label className="mr-2">Select Size:</label>
            <div className="flex flex-wrap">
              {product.stockOptions.map((option) => (
                <button
                  key={option.size}
                  onClick={() => setSelectedSize(option.size)}
                  disabled={option.stock <= 0}
                  className={`m-1 p-2 rounded border ${selectedSize === option.size ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'} ${option.stock <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {option.size}
                  {option.stock > 0 ? ' (In Stock)' : ' (Out of Stock)'}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="mr-2">Select Color:</label>
            <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} className="border p-2 rounded bg-gray-800 text-white">
              <option value="">Select</option>
              {product.stockOptions
                .map(option => option.color)
                .filter((value, index, self) => self.indexOf(value) === index) // Get unique colors
                .map(color => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="mr-2">Add Text:</label>
            <input
              type="text"
              value={customText}
              onChange={(e) => setCustomText(e.target.value)}
              className="border p-2 rounded bg-gray-800 text-white"
              placeholder="Enter custom text"
            />
          </div>
          <div className="mb-4">
            <label className="mr-2">Gift Wrap:</label>
            <input
              type="checkbox"
              checked={giftWrap}
              onChange={() => setGiftWrap(!giftWrap)}
              className="border p-2 rounded bg-gray-800 text-white"
            />
          </div>
          {giftWrap && (
            <div className="mb-4">
              <label className="mr-2">Gift Message:</label>
              <input
                type="text"
                value={giftMessage}
                onChange={(e) => setGiftMessage(e.target.value)}
                className="border p-2 rounded bg-gray-800 text-white"
                placeholder="Enter gift message"
              />
            </div>
          )}
          <button
            onClick={handleAddToCart}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaShoppingCart className="mr-2" /> Add to Cart
          </button>
          <button
            onClick={handleWishlist}
            className="ml-4 bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaHeart className="mr-2" /> Wishlist
          </button>
          <div className="mt-4">
            <h3 className="text-2xl font-bold mb-2">Related Products</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {relatedProducts.map((product) => (
                <div key={product.id} className="border rounded-lg p-4 shadow-lg">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${product.imageUrl}`}
                    alt={product.name}
                    className="w-full h-32 object-contain rounded-lg mb-2"
                  />
                  <h4 className="text-xl font-bold">{product.name}</h4>
                  <p className="text-lg font-semibold">£{product.price}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <h3 className="text-2xl font-bold mb-2">Reviews</h3>
            <div className="space-y-4">
              {reviews.map(review => (
                <div key={review.id} className="border rounded-lg p-4 shadow-lg bg-gray-800">
                  <div className="flex items-center mb-2">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} className={i < review.rating ? 'text-yellow-500' : 'text-gray-500'} />
                    ))}
                  </div>
                  <p>{review.comment}</p>
                </div>
              ))}
            </div>
            {userHasPurchased && (
              <div className="mt-4">
                <h4 className="text-xl font-bold mb-2">Add Your Review</h4>
                <div className="mb-2">
                  <label className="mr-2">Rating:</label>
                  <select
                    name="rating"
                    value={userReview.rating}
                    onChange={(e) => setUserReview({ ...userReview, rating: e.target.value })}
                    className="border p-2 rounded bg-gray-800 text-white"
                  >
                    <option value={0}>Select rating</option>
                    {[...Array(5)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1} Star{i > 0 && 's'}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2">
                  <label className="mr-2">Comment:</label>
                  <textarea
                    name="comment"
                    value={userReview.comment}
                    onChange={(e) => setUserReview({ ...userReview, comment: e.target.value })}
                    className="border p-2 rounded bg-gray-800 text-white w-full"
                    placeholder="Write your review here"
                  />
                </div>
                <button
                  onClick={handleReviewSubmit}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit Review
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Product not found</p>
      )}
    </div>
  );
};

export default ProductPage;
