import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const SharedWishlist = () => {
    const { token } = useParams();
    const [wishlist, setWishlist] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWishlist = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/wishlist/${token}`);
                setWishlist(response.data);
            } catch (error) {
                console.error('Failed to fetch shared wishlist:', error);
                toast.error('Failed to fetch shared wishlist');
            } finally {
                setLoading(false);
            }
        };

        fetchWishlist();
    }, [token]);

    if (loading) {
        return <div className="text-white">Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8 bg-black min-h-screen">
            <h1 className="text-3xl font-semibold mb-8 text-white">Shared Wishlist</h1>
            {wishlist.length > 0 ? (
                <ul>
                    {wishlist.map(item => (
                        <li key={item.id} className="flex justify-between items-center mb-4">
                            <Link to={`/product/${item.Product.id}`} className="text-white flex items-center space-x-4">
                                <img src={`${process.env.REACT_APP_API_URL}/${item.Product.imageUrl}`} alt={item.Product.name} className="w-16 h-16 object-cover rounded" />
                                <span>{item.Product.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-white">No items in wishlist</p>
            )}
        </div>
    );
};

export default SharedWishlist;
