import React from 'react';
import { FaMoon, FaSun, FaBell, FaUserCircle, FaSearch } from 'react-icons/fa';

const TopBar = ({ toggleDarkMode, darkMode }) => {
  return (
    <div className={`p-4 shadow ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} flex justify-between items-center`}>
      <div className="flex items-center space-x-4">
        <h1 className="text-2xl font-bold">Admin Dashboard</h1>
        <div className="relative">
          <input
            type="text"
            className="p-2 pl-10 rounded-lg bg-gray-200 focus:bg-white focus:outline-none"
            placeholder="Search..."
          />
          <FaSearch className="absolute top-1/2 transform -translate-y-1/2 left-3 text-gray-500" />
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <FaBell className="text-2xl cursor-pointer hover:text-blue-500" />
        <FaUserCircle className="text-2xl cursor-pointer hover:text-blue-500" />
        <button onClick={toggleDarkMode} className="text-2xl p-2 rounded-full focus:outline-none">
          {darkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
    </div>
  );
};

export default TopBar;
