import React from 'react';

const BulkActionModal = ({ selectedProducts, onApply, onClose }) => {
  const handleApply = () => {
    onApply('delete'); // Example: Apply a delete action
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Bulk Actions</h2>
        <p>{`You have selected ${selectedProducts.length} products.`}</p>
        <div className="mt-4">
          <button onClick={handleApply} className="bg-red-500 text-white py-2 px-4 rounded mr-2">
            Apply Delete
          </button>
          <button onClick={onClose} className="bg-gray-500 text-white py-2 px-4 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkActionModal;
