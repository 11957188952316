import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import NodeCache from 'node-cache';

const StoreContext = createContext();
const cache = new NodeCache({ stdTTL: 60 }); // Cache API responses for 60 seconds

export const useStore = () => useContext(StoreContext);

export const StoreProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [cryptoRate, setCryptoRate] = useState(null);

  const fetchCryptoRate = async () => {
    try {
      const cachedRate = cache.get('cryptoRate');
      if (cachedRate) {
        setCryptoRate(cachedRate);
      } else {
        const response = await retry(() => axios.get('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'), 3);
        const usdToGbpRate = 0.78;
        const rate = (response.data.price * usdToGbpRate).toFixed(4);

        if (isNaN(rate)) {
          throw new Error('Failed to calculate the cryptocurrency rate.');
        }

        cache.set('cryptoRate', rate);
        setCryptoRate(rate);
      }
    } catch (error) {
      console.error('Failed to fetch crypto rate:', error.message);
    }
  };

  useEffect(() => {
    fetchCryptoRate();

    const interval = setInterval(fetchCryptoRate, 60000);
    return () => clearInterval(interval);
  }, []);

  const retry = async (fn, retries) => {
    let error;
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (err) {
        error = err;
        const delay = Math.pow(2, i) * 1000;
        await new Promise(res => setTimeout(res, delay));
      }
    }
    throw error;
  };

  return (
    <StoreContext.Provider value={{ searchResults, setSearchResults, cryptoRate }}>
      {children}
    </StoreContext.Provider>
  );
};
