// src/components/StatusFilter.js
import React from 'react';

const StatusFilter = ({ filter, onChange }) => {
  return (
    <select value={filter} onChange={(e) => onChange(e.target.value)} className="border rounded p-2 bg-gray-700 text-white">
      <option value="all">All</option>
      <option value="pending">Pending</option>
      <option value="processed">Processed</option>
      <option value="shipped">Shipped</option>
      <option value="delivered">Delivered</option>
    </select>
  );
};

export default StatusFilter;
