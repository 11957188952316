import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import {
  FaSearch,
  FaSort,
  FaSync,
  FaTimesCircle,
  FaCheckCircle,
  FaSpinner,
  FaDownload,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';

Modal.setAppElement('#root');

const CryptoPaymentsManagement = () => {
  const [cryptoPayments, setCryptoPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ search: '', status: '', sort: '', startDate: '', endDate: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    const fetchCryptoPayments = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/crypto/crypto-payments`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: { ...filters, page: currentPage, limit: itemsPerPage },
        });

        // Ensure the response data is always an array, even if empty
        setCryptoPayments(response.data.cryptoPayments || []);
        setTotalPages(response.data.totalPages || 1);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch crypto payments:', error);
        setError('Failed to load crypto payments.');
        setLoading(false);
      }
    };

    fetchCryptoPayments();
  }, [filters, currentPage, itemsPerPage]);

  const handleSearchChange = (e) => setFilters({ ...filters, search: e.target.value });

  const handleSortChange = (sortKey) => {
    let direction = 'asc';
    if (filters.sort === `${sortKey}_asc`) {
      direction = 'desc';
    }
    setFilters({ ...filters, sort: `${sortKey}_${direction}` });
  };

  const handleStatusChange = (status) => setFilters({ ...filters, status });

  const handlePageChange = (page) => setCurrentPage(page);

  const handleJumpToPage = (e) => {
    const page = Number(e.target.value);
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const togglePaymentSelection = (paymentId) => {
    if (selectedPayments.includes(paymentId)) {
      setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
    } else {
      setSelectedPayments([...selectedPayments, paymentId]);
    }
  };

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/crypto/crypto-payments/export`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: { ...filters },
      });

      const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'crypto_payments.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Crypto payments exported successfully!');
    } catch (error) {
      console.error('Failed to export crypto payments:', error);
      toast.error('Failed to export crypto payments.');
    } finally {
      setIsExporting(false);
    }
  };

  const openDetailModal = (payment) => {
    setSelectedPayment(payment);
    setIsDetailModalOpen(true);
  };

  return (
    <div className="ml-64 p-6">
      <h1 className="text-3xl font-bold mb-4">Manage Crypto Payments</h1>
      <div className="mb-4 flex justify-between">
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Search by Transaction ID or Address..."
            className="p-2 border border-gray-400 rounded"
            value={filters.search}
            onChange={handleSearchChange}
          />
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded ${filters.sort.includes('amount') && 'bg-blue-700'}`}
            onClick={() => handleSortChange('amount')}
          >
            <FaSort /> Sort by Amount
          </button>
          <button
            className={`bg-blue-500 text-white py-2 px-4 rounded ${filters.sort.includes('createdAt') && 'bg-blue-700'}`}
            onClick={() => handleSortChange('createdAt')}
          >
            <FaSort /> Sort by Date
          </button>
          <select
            value={filters.status}
            onChange={(e) => handleStatusChange(e.target.value)}
            className="p-2 border border-gray-400 rounded"
          >
            <option value="">All Statuses</option>
            <option value="confirmed">Confirmed</option>
            <option value="pending">Pending</option>
            <option value="failed">Failed</option>
            <option value="refunded">Refunded</option>
          </select>
        </div>
        <div className="flex space-x-4">
          <button
            className="bg-green-500 text-white py-2 px-4 rounded"
            onClick={handleExport}
            disabled={isExporting}
          >
            {isExporting ? <FaSpinner className="animate-spin" /> : <FaDownload />} Export Payments
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-3xl text-blue-500" />
        </div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selectedPayments.length === cryptoPayments.length}
                    onChange={(e) =>
                      setSelectedPayments(e.target.checked ? cryptoPayments.map((p) => p.id) : [])
                    }
                  />
                </th>
                <th className="py-2 px-4 border-b">Transaction ID</th>
                <th className="py-2 px-4 border-b">From Address</th>
                <th className="py-2 px-4 border-b">To Address</th>
                <th className="py-2 px-4 border-b">Amount (ETH)</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {cryptoPayments.length > 0 ? (
                cryptoPayments.map((payment) => (
                  <tr key={payment.id}>
                    <td className="py-2 px-4 border-b">
                      <input
                        type="checkbox"
                        checked={selectedPayments.includes(payment.id)}
                        onChange={() => togglePaymentSelection(payment.id)}
                      />
                    </td>
                    <td className="py-2 px-4 border-b">{payment.transactionHash}</td>
                    <td className="py-2 px-4 border-b">{payment.fromAddress}</td>
                    <td className="py-2 px-4 border-b">{payment.toAddress}</td>
                    <td className="py-2 px-4 border-b">{payment.amount} ETH</td>
                    <td className="py-2 px-4 border-b">{payment.status}</td>
                    <td className="py-2 px-4 border-b flex space-x-2">
                      <button
                        onClick={() => openDetailModal(payment)}
                        className="bg-blue-500 text-white py-1 px-2 rounded"
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">
                    No crypto payments found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              <FaChevronLeft /> Previous
            </button>
            <div className="flex items-center space-x-2">
              <span>Page {currentPage} of {totalPages}</span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={currentPage}
                onChange={handleJumpToPage}
                className="w-16 text-center p-2 border border-gray-400 rounded"
              />
            </div>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Next <FaChevronRight />
            </button>
          </div>
        </>
      )}

      {isDetailModalOpen && selectedPayment && (
        <Modal
          isOpen={isDetailModalOpen}
          onRequestClose={() => setIsDetailModalOpen(false)}
          contentLabel="Transaction Details"
          className="relative bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-lg mx-auto my-8 z-50 text-white"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40"
        >
          <h2 className="text-2xl font-bold mb-4">Transaction Details</h2>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Transaction ID</label>
            <p className="text-lg">{selectedPayment.transactionHash}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">From Address</label>
            <p className="text-lg">{selectedPayment.fromAddress}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">To Address</label>
            <p className="text-lg">{selectedPayment.toAddress}</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Amount</label>
            <p className="text-lg">{selectedPayment.amount} ETH</p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium">Status</label>
            <p className="text-lg">{selectedPayment.status}</p>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsDetailModalOpen(false)}
              className="bg-gray-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      <ToastContainer />
    </div>
  );
};

export default CryptoPaymentsManagement;
