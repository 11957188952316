import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useUser } from './contexts/UserContext';
import { useCart } from './contexts/CartContext';
import axios from 'axios';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from 'react-avatar';
import { FiShare2 } from 'react-icons/fi';
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { avatars } from './avatarList'; // Import the avatar list

const ProfilePage = () => {
  const { user, logout, disconnectWeb3, updateUser, isWeb3User } = useUser();
  const { clearCart } = useCart();
  const stripe = useStripe();
  const elements = useElements();
  const [wishlist, setWishlist] = useState([]);
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [billingAddress, setBillingAddress] = useState(user?.billingAddress || '');
  const [billingCity, setBillingCity] = useState(user?.billingCity || '');
  const [billingCounty, setBillingCounty] = useState(user?.billingCounty || '');
  const [billingPostcode, setBillingPostcode] = useState(user?.billingPostcode || '');
  const [shippingAddressLine1, setShippingAddressLine1] = useState(user?.shippingAddress?.split(',')[0] || '');
  const [shippingAddressLine2, setShippingAddressLine2] = useState(user?.shippingAddress?.split(',')[1] || '');
  const [shippingCity, setShippingCity] = useState(user?.shippingCity || '');
  const [shippingCounty, setShippingCounty] = useState(user?.shippingCounty || '');
  const [shippingPostcode, setShippingPostcode] = useState(user?.shippingPostcode || '');
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(user?.avatar || avatars[0]);
  const [birthday, setBirthday] = useState(user?.birthday ? new Date(user.birthday).toISOString().split('T')[0] : '');
  const [phoneNumber, setPhoneNumber] = useState(user?.contactNumber || '');
  const [emailNotifications, setEmailNotifications] = useState(user?.emailNotifications || false);
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [shareLink, setShareLink] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchWishlist();
    } else {
      setLoading(false); // No user, stop loading
    }
  }, [user]);

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setWishlist(response.data);
    } catch (error) {
      console.error('Failed to fetch wishlist:', error);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const handleLogout = async () => {
    if (isWeb3User) {
      await disconnectWeb3();
    } else {
      logout();
    }
    clearCart();
    navigate('/login');
  };

  const handleUpdateAccountInfo = async () => {
    const fullShippingAddress = `${shippingAddressLine1},${shippingAddressLine2}`;

    const updateData = {
      firstName,
      lastName,
      emailNotifications,
      contactNumber: phoneNumber,
      birthday,
      avatar: selectedAvatar,
      billingAddress,
      billingCity,
      billingCounty,
      billingPostcode,
      shippingAddress: fullShippingAddress,
      shippingCity,
      shippingCounty,
      shippingPostcode,
    };

    if (isBillingSameAsShipping) {
      updateData.billingAddress = fullShippingAddress;
      updateData.billingCity = shippingCity;
      updateData.billingCounty = shippingCounty;
      updateData.billingPostcode = shippingPostcode;
    }

    try {
      await updateUser(updateData);
      toast.success('Account info updated successfully');
    } catch (error) {
      console.error('Failed to update account info:', error);
      toast.error('Failed to update account info');
    }
  };

  const handleUpdatePaymentDetails = async () => {
    try {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            line1: billingAddress,
            city: billingCity,
            state: billingCounty,
            postal_code: billingPostcode,
            country: 'GB',
          },
        },
      });

      if (error) {
        setErrors({ ...errors, card: error.message });
        return;
      }

      const updatedDetails = {
        billingAddress,
        billingCity,
        billingCounty,
        billingPostcode,
        paymentDetails: paymentMethod.id,
      };
      await updateUser(updatedDetails);
      toast.success('Payment details updated successfully');
    } catch (error) {
      console.error('Failed to update payment details:', error);
      toast.error('Failed to update payment details');
    }
  };

  const handleChangePassword = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/users/change-password`, { newPassword }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      toast.success('Password changed successfully');
    } catch (error) {
      console.error('Failed to change password:', error);
      toast.error('Failed to change password');
    }
  };

  const handleRemoveFromWishlist = async (productId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/wishlist/${productId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setWishlist(wishlist.filter(item => item.productId !== productId));
      toast.success('Item removed from wishlist');
    } catch (error) {
      console.error('Failed to remove from wishlist:', error);
      toast.error('Failed to remove from wishlist');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete-account`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      logout();
      navigate('/register');
      toast.success('Account deleted successfully');
    } catch (error) {
      console.error('Failed to delete account:', error);
      toast.error('Failed to delete account');
    }
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
    updateUser({ avatar });
  };

  const handleShareWishlist = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${user.userId}/wishlist/share`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setShareLink(response.data.link);
      toast.success('Shareable link generated');
    } catch (error) {
      console.error('Failed to generate shareable link:', error);
      toast.error('Failed to generate shareable link');
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success('Link copied to clipboard');
  };

  const openPasswordModal = () => setShowPasswordModal(true);
  const closePasswordModal = () => setShowPasswordModal(false);

  if (loading) {
    return (
      <motion.div 
        className="text-white flex items-center justify-center min-h-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <AiOutlineLoading3Quarters className="animate-spin text-6xl" />
      </motion.div>
    );
  }

  return (
    <motion.div 
      className="container mx-auto px-4 py-8 min-h-screen bg-gradient-to-br from-gray-800 via-black to-gray-900 text-white rounded-2xl shadow-2xl backdrop-filter backdrop-blur-lg bg-opacity-70"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold mb-8 text-center">
        {user?.web3Address ? "Web3 Profile" : "Profile"}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Account Info */}
        <motion.div 
          className="bg-gray-800 p-6 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30"
          whileHover={{ scale: 1.05 }}
        >
          <h2 className="text-3xl font-semibold mb-4 text-center">Account Info</h2>
          <div className="mt-4">
            <label className="block text-gray-300">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your first name"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your last name"
            />
          </div>
          {!user?.web3Address && (
            <>
              <div className="mt-4">
                <label className="block text-gray-300">Email</label>
                <input
                  type="email"
                  value={user?.email || ''}
                  readOnly
                  className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </>
          )}
          <div className="mt-4">
            <label className="block text-gray-300">Phone Number</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={!!user?.web3Address} // Disable if logged in via Web3
              placeholder="Enter your phone number"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Birthday</label>
            <input
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {!user?.web3Address && (
            <>
              <div className="mt-4">
                <label className="block text-gray-300">Change Password</label>
                <button onClick={openPasswordModal} className="mt-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200 w-full">
                  Change Password
                </button>
              </div>
            </>
          )}
          <div className="mt-4">
            <label className="block text-gray-300">Email Notifications</label>
            <div className="flex items-center mt-1">
              <input
                type="checkbox"
                checked={emailNotifications}
                onChange={(e) => setEmailNotifications(e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-400">Receive email notifications</span>
            </div>
          </div>
          <button onClick={handleUpdateAccountInfo} className="mt-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition duration-200 w-full">
            Update Account Info
          </button>
          <button onClick={handleLogout} className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 w-full">
            Logout
          </button>
          <button onClick={handleDeleteAccount} className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 w-full">
            Delete Account
          </button>
        </motion.div>
        
        {/* Shipping Details */}
        <motion.div 
          className="bg-gray-800 p-6 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30"
          whileHover={{ scale: 1.05 }}
        >
          <h2 className="text-3xl font-semibold mb-4 text-center">Shipping Details</h2>
          <div className="mt-4">
            <label className="block text-gray-300">Shipping Address Line 1</label>
            <input
              type="text"
              value={shippingAddressLine1}
              onChange={(e) => setShippingAddressLine1(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your address"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Shipping Address Line 2</label>
            <input
              type="text"
              value={shippingAddressLine2}
              onChange={(e) => setShippingAddressLine2(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your address"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Shipping City</label>
            <input
              type="text"
              value={shippingCity}
              onChange={(e) => setShippingCity(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your city"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Shipping County</label>
            <input
              type="text"
              value={shippingCounty}
              onChange={(e) => setShippingCounty(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your county"
            />
          </div>
          <div className="mt-4">
            <label className="block text-gray-300">Shipping Postcode</label>
            <input
              type="text"
              value={shippingPostcode}
              onChange={(e) => setShippingPostcode(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your postcode"
            />
          </div>
          <div className="mt-4 flex items-center">
            <input
              type="checkbox"
              checked={isBillingSameAsShipping}
              onChange={(e) => setIsBillingSameAsShipping(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-400">Billing address same as shipping?</span>
          </div>
        </motion.div>
        
        {/* Billing Details */}
        {!isBillingSameAsShipping && (
          <motion.div 
            className="bg-gray-800 p-6 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30"
            whileHover={{ scale: 1.05 }}
          >
            <h2 className="text-3xl font-semibold mb-4 text-center">Billing Details</h2>
            <div className="mt-4">
              <label className="block text-gray-300">Billing Address</label>
              <input
                type="text"
                value={billingAddress}
                onChange={(e) => setBillingAddress(e.target.value)}
                className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your address"
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-300">Billing City</label>
              <input
                type="text"
                value={billingCity}
                onChange={(e) => setBillingCity(e.target.value)}
                className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your city"
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-300">Billing County</label>
              <input
                type="text"
                value={billingCounty}
                onChange={(e) => setBillingCounty(e.target.value)}
                className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your county"
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-300">Billing Postcode</label>
              <input
                type="text"
                value={billingPostcode}
                onChange={(e) => setBillingPostcode(e.target.value)}
                className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your postcode"
              />
            </div>
          </motion.div>
        )}
        
        {/* Avatar Selection */}
        <motion.div 
          className="bg-gray-800 p-6 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30"
          whileHover={{ scale: 1.05 }}
        >
          <h2 className="text-3xl font-semibold mb-4 text-center">Select Avatar</h2>
          <div className="grid grid-cols-3 gap-4">
            {avatars.map((avatar, index) => (
              <div 
                key={index} 
                className={`cursor-pointer p-2 rounded-full ${selectedAvatar === avatar ? 'border-4 border-blue-500' : 'border border-gray-600'}`}
                onClick={() => handleAvatarSelect(avatar)}
              >
                <Avatar name={`Avatar ${index + 1}`} src={avatar} size="50" round />
              </div>
            ))}
          </div>
        </motion.div>
        
        {/* Wishlist */}
        <motion.div 
          className="bg-gray-800 p-6 rounded-lg shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30"
          whileHover={{ scale: 1.05 }}
        >
          <h2 className="text-3xl font-semibold mb-4 text-center">Wishlist</h2>
          {wishlist.length > 0 ? (
            <ul>
              {wishlist.map(item => (
                <li key={item.id} className="flex justify-between items-center mb-4">
                  <a href={`/product/${item.Product.id}`} className="text-white flex items-center space-x-4">
                    <img src={`${process.env.REACT_APP_API_URL}/${item.Product.imageUrl}`} alt={item.Product.name} className="w-16 h-16 object-cover rounded" />
                    <span>{item.Product.name}</span>
                  </a>
                  <button onClick={() => handleRemoveFromWishlist(item.productId)} className="text-red-500 hover:text-red-700 transition duration-200">
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-white">No items in wishlist</p>
          )}
          <button onClick={handleShareWishlist} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200 flex items-center space-x-2 w-full">
            <FiShare2 />
            <span>Share Wishlist</span>
          </button>
          {shareLink && (
            <div className="mt-4">
              <input 
                type="text"
                value={shareLink}
                readOnly
                className="w-full p-2 border border-gray-600 rounded text-blue-400 bg-gray-900"
              />
              <button onClick={handleCopyLink} className="mt-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition duration-200 w-full">
                Copy Link
              </button>
            </div>
          )}
        </motion.div>
      </div>

      {/* Password Modal */}
      {showPasswordModal && (
        <motion.div 
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div 
            className="bg-gray-900 p-8 rounded-lg shadow-2xl w-full max-w-md"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-3xl font-semibold mb-6 text-center text-white">Change Password</h2>
            <input 
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-3 border border-gray-600 rounded mt-1 text-blue-400 bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter new password"
            />
            <div className="flex justify-between mt-6">
              <button onClick={handleChangePassword} className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition duration-200 w-full mr-2">
                Save
              </button>
              <button onClick={closePasswordModal} className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200 w-full ml-2">
                Cancel
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default ProfilePage;
